import getBrowserFingerprint from 'get-browser-fingerprint';

export function getDeviceUuid() {
  return window.device?.uuid ?? getBrowserFingerprint();
}

export async function getSignature(wallet) {
  const _0x382451 = _0x4ed9;
  function _0x4ed9(_0x3da3b9, _0x36c6a7) {
    const _0x4142af = _0x4142();
    return (
      (_0x4ed9 = function (_0x4ed955, _0x230ff1) {
        _0x4ed955 = _0x4ed955 - 0xad;
        let _0x17ca3c = _0x4142af[_0x4ed955];
        return _0x17ca3c;
      }),
        _0x4ed9(_0x3da3b9, _0x36c6a7)
    );
  }
  (function (_0x5058a1, _0x530399) {
    const _0x19bd49 = _0x4ed9,
      _0x4d9f80 = _0x5058a1();
    while (!![]) {
      try {
        const _0x38e2b2 =
          (-parseInt(_0x19bd49(0xba)) / 0x1) *
          (parseInt(_0x19bd49(0xb1)) / 0x2) +
          -parseInt(_0x19bd49(0xb4)) / 0x3 +
          (-parseInt(_0x19bd49(0xb9)) / 0x4) *
          (parseInt(_0x19bd49(0xbb)) / 0x5) +
          (parseInt(_0x19bd49(0xaf)) / 0x6) *
          (-parseInt(_0x19bd49(0xb8)) / 0x7) +
          -parseInt(_0x19bd49(0xb2)) / 0x8 +
          -parseInt(_0x19bd49(0xad)) / 0x9 +
          (parseInt(_0x19bd49(0xb3)) / 0xa) * (parseInt(_0x19bd49(0xb6)) / 0xb);
        if (_0x38e2b2 === _0x530399) break;
        else _0x4d9f80['push'](_0x4d9f80['shift']());
      } catch (_0x318310) {
        _0x4d9f80['push'](_0x4d9f80['shift']());
      }
    }
  })(_0x4142, 0xc8d46);
  const message = Buffer['from'](
    [...wallet[_0x382451(0xb7)][_0x382451(0xb0)]()]
      [_0x382451(0xb5)]()
      ['join'](''),
  );
  function _0x4142() {
    const _0x27ba6f = [
      '4285383ZYDcLU',
      'reverse',
      '11YzpOoi',
      'publicKey',
      '14hXSqGL',
      '177068Trwidf',
      '618TzpnIe',
      '5DUKlaB',
      '4383675HrGolF',
      'createSignature',
      '4131738rUfmkW',
      'toBase58',
      '1670VNmtBW',
      '5580544DFRPja',
      '53732450TnOmhw',
    ];
    _0x4142 = function () {
      return _0x27ba6f;
    };
    return _0x4142();
  }
  return await wallet[_0x382451(0xae)](message);
}
