import { useEffect, useRef } from 'react';
import { chromeLocalStorage } from './chrome-storage-polyfill';

export function usePolling(pollingFunc: () => void, intervalMs: number) {
  const pollingRef = useRef<ReturnType<typeof setTimeout> | null>(null);
  useEffect(() => {
    const startPolling = () => {
      pollingRef.current = setInterval(() => {
        pollingFunc();
      }, intervalMs);
    };
    startPolling();

    return () => {
      if (pollingRef.current) {
        clearInterval(pollingRef.current);
      }
    };
  }, []);
}

export function useLocalStorage<T>(storageKey: string, callback?: (val: T) => void) {
  useEffect(() => {
    const updateState = () => {
      chromeLocalStorage.get(storageKey, (result: any) => {
        if (callback) {
          const value: T = result[storageKey];
          callback(value);
        }
      });
    };
    const listener = (changes) => {
      if (storageKey in changes) {
        updateState();
      }
    };
    updateState();

    chromeLocalStorage.onChanged.addListener(listener);
    return () => chromeLocalStorage.onChanged.removeListener(listener);
  }, []);
}
