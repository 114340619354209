import { Tab, Tabs, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import { Close } from '@material-ui/icons';
import { Connection, PublicKey, Transaction } from '@solana/web3.js';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Decimal } from 'decimal.js';
import QrcodeIcon from 'mdi-material-ui/Qrcode';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { createPortal, flushSync } from 'react-dom';
import strings from '../../../localization';
import { useConnection } from '../../../utils/connection';
import {
  USDT_BRIDGE_SERVICE_ADDRESS,
  USDT_BRIDGE_URL,
} from '../../../utils/env-variables';
import { signAndSendTransaction, transferTokens } from '../../../utils/tokens';
import { TokenAccount, useTokenAccounts } from '../../../utils/tokens/hooks';
import { USDT_PROGRAM_ID } from '../../../utils/tokens/instructions';
import { estimateTransactionSize } from '../../../utils/transactions';
import { isExtension, useDebounce } from '../../../utils/utils';
import { useBalanceInfo, useWallet, Wallet } from '../../../utils/wallet';
import DialogForm from '../../DialogForm';

const DOMI_ADDRESS_REGEX = /[1-9A-HJ-NP-Za-km-z]{32,44}/;
const BSC_ADDRESS_REGEX = /^0x[a-fA-F0-9]{40}$/;

const NETWORKS = {
  domichain: 'Domichain',
  bsc: 'Binance Smart Chain',
};

export default function UsdtSendDialog({
  open,
  onClose,
  publicKey,
  initialNetwork = 'domichain',
}: {
  open: boolean;
  onClose: () => void;
  publicKey: PublicKey;
  initialNetwork: keyof typeof NETWORKS;
}) {
  const onSubmitRef = useRef<() => void>();
  const [selectedNetwork, setSelectedNetwork] = useState(initialNetwork);

  const balanceInfo = useBalanceInfo(publicKey);
  const { data: tokenAccounts } = useTokenAccounts(USDT_PROGRAM_ID);

  const totalAmount =
    tokenAccounts && !publicKey
      ? tokenAccounts.reduce((acc, curr) => {
          const tokenAmount = curr.account.data.parsed.info.tokenAmount;
          return acc + tokenAmount.uiAmount;
        }, 0)
      : balanceInfo
      ? balanceInfo.amount
      : 0;

  const form = useForm(selectedNetwork, setSelectedNetwork, totalAmount);
  const transferAmount = useMemo(
    () =>
      /^\d+\.?\d*$/.test(form.transferAmountString)
        ? new Decimal(form.transferAmountString)
        : new Decimal(0),
    [form.transferAmountString],
  );

  return (
    <>
      <DialogForm
        open={open}
        onClose={onClose}
        onSubmit={() => onSubmitRef.current?.()}
        fullWidth
      >
        <DialogTitle>{strings.send} USDT</DialogTitle>
        <Tabs
          value={selectedNetwork}
          variant="fullWidth"
          onChange={(_, value) => setSelectedNetwork(value)}
          textColor="primary"
          indicatorColor="primary"
        >
          {Object.entries(NETWORKS).map(([id, name]) => (
            <Tab key={id} label={name} value={id} />
          ))}
        </Tabs>
        {selectedNetwork === 'domichain' ? (
          <SendDplDialog
            form={form}
            transferAmount={transferAmount}
            publicKey={publicKey}
            onSubmitRef={onSubmitRef}
            onClose={onClose}
          />
        ) : (
          <SendUsdtDialog
            form={form}
            transferAmount={transferAmount}
            publicKey={publicKey}
            onSubmitRef={onSubmitRef}
            onClose={onClose}
          />
        )}
      </DialogForm>
    </>
  );
}

function SendDplDialog({
  form,
  transferAmount,
  publicKey,
  onSubmitRef,
  onClose,
}: {
  form: ReturnType<typeof useForm>;
  transferAmount: Decimal;
  publicKey: PublicKey;
  onSubmitRef: React.MutableRefObject<(() => void) | undefined>;
  onClose: () => void;
}) {
  const wallet = useWallet();
  const connection = useConnection();

  const { mutate, isLoading } = usePerformTransferMutation(
    async (data) =>
      await transferTokensBatched(
        connection,
        wallet,
        data,
        new PublicKey(form.destinationAddress),
      ),
  );

  onSubmitRef.current = () =>
    mutate({ tokenPubkey: publicKey, transferAmount });

  const isDisabled =
    !form.validForm ||
    !transferAmount ||
    transferAmount.lessThanOrEqualTo(0) ||
    isLoading;

  return (
    <>
      <DialogContent>{form.fields}</DialogContent>
      <DialogActions
        style={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <div>
          <Button onClick={onClose}>{strings.cancel}</Button>
          <Button type="submit" color="primary" disabled={isDisabled}>
            {strings.send}
          </Button>
        </div>
      </DialogActions>
    </>
  );
}

function SendUsdtDialog({
  form,
  transferAmount,
  publicKey,
  onSubmitRef,
  onClose,
}: {
  form: ReturnType<typeof useForm>;
  transferAmount: Decimal;
  publicKey: PublicKey;
  onSubmitRef: React.MutableRefObject<(() => void) | undefined>;
  onClose: () => void;
}) {
  const wallet = useWallet();
  const connection = useConnection();
  const { enqueueSnackbar } = useSnackbar();

  const { mutate, isLoading } = usePerformTransferMutation(async (data) => {
    await transferTokensBatched(
      connection,
      wallet,
      data,
      new PublicKey(USDT_BRIDGE_SERVICE_ADDRESS!),
    );

    const requestBody = {
      domi_address: wallet.publicKey.toBase58(),
      withdraw_address: form.destinationAddress,
      mints: data.map((value) => {
        const accountInfo = value.account.account.data.parsed.info;
        return {
          mint_address: accountInfo.mint,
          withdraw_amount: value.transferAmount
            .mul(10 ** accountInfo.tokenAmount.decimals)
            .toString(),
          decimals: accountInfo.tokenAmount.decimals,
        };
      }),
    };

    const requestSignature = await wallet.createSignature(
      new TextEncoder().encode(JSON.stringify(requestBody)),
    );

    const response = await fetch(`${USDT_BRIDGE_URL}/withdraw`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({
        ...requestBody,
        signature: requestSignature,
      }),
    });

    if (response.ok) {
      const json = await response.json();
      console.debug('/withdraw json', json);

      if (json?.status !== 'ok') {
        throw new Error(strings.failedToSignAndSendAMultisigTransaction);
      }

      enqueueSnackbar('Withdrawal successful', {
        variant: 'success',
        autoHideDuration: 10000,
      });

      onClose();
    } else {
      throw Error(strings.requestFailed);
    }
  });

  const isDisabled =
    !form.validForm ||
    !transferAmount ||
    transferAmount.lessThanOrEqualTo(0) ||
    isLoading;

  onSubmitRef.current = () =>
    mutate({ tokenPubkey: publicKey, transferAmount });

  return (
    <>
      <DialogContent>{form.fields}</DialogContent>
      <DialogActions
        style={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <div>
          <Button onClick={onClose}>{strings.cancel}</Button>
          <Button type="submit" color="primary" disabled={isDisabled}>
            {strings.send}
          </Button>
        </div>
      </DialogActions>
    </>
  );
}

function useForm(
  selectedNetwork: keyof typeof NETWORKS,
  setSelectedNetwork: (network: keyof typeof NETWORKS) => void,
  balanceAmount: number,
) {
  const [scannerStatus, setScannerStatus] = useState<any>();
  const [scannerIsVisible, setScannerIsVisible] = useState(false);

  const [destinationAddress, setDestinationAddress] = useState('');
  const [transferAmountString, setTransferAmountString] = useState('');

  const addressRegex =
    selectedNetwork === 'bsc' ? BSC_ADDRESS_REGEX : DOMI_ADDRESS_REGEX;

  const validAddress =
    destinationAddress.length <= 0 || addressRegex.test(destinationAddress);

  const amount = /^\d+\.?\d*$/.test(transferAmountString)
    ? new Decimal(transferAmountString)
    : new Decimal(0);

  const validAmount =
    amount.greaterThan(0) && amount.lessThanOrEqualTo(balanceAmount);

  const presentQrScanner = useCallback(() => {
    window.QRScanner.prepare((_, status) => {
      setScannerStatus(status);
      setScannerIsVisible(true);
    });
  }, []);

  useEffect(() => {
    if (scannerIsVisible) {
      if (scannerStatus?.denied) {
        window.QRScanner.openSettings();
        return;
      }

      if (!scannerStatus?.authorized) {
        return;
      }

      const handleScan = (err, text) => {
        if (err) {
          setScannerIsVisible(false);
          return;
        }

        if (text) {
          if (BSC_ADDRESS_REGEX.test(text)) {
            setSelectedNetwork('bsc');
          } else if (DOMI_ADDRESS_REGEX.test(text)) {
            setSelectedNetwork('domichain');
          } else {
            return;
          }

          setDestinationAddress(text);
          setScannerIsVisible(false);
        }
      };

      const dialogElements = document.querySelectorAll<HTMLElement>(
        '[role="presentation"]',
      );
      const rootElement = document.getElementById('root');

      window.QRScanner.scan(handleScan);
      window.QRScanner.show(() => {
        dialogElements.forEach((el) => (el.style.display = 'none'));
        rootElement!.style.display = 'none';
      });

      return () => {
        window.QRScanner.cancelScan();
        window.QRScanner.hide(() => {
          dialogElements.forEach((el) => (el.style.display = ''));
          rootElement!.style.display = '';
        });
      };
    }
  }, [scannerStatus, scannerIsVisible, addressRegex, setSelectedNetwork]);

  const { isCalculatingFees, children: feeChildren } = useFees({
    transferAmount: amount,
    withdrawAddress: destinationAddress,
    validFields: validAddress && validAmount,
    enabled: selectedNetwork === 'bsc',
  });

  const fields = (
    <>
      <TextField
        label={strings.recipientAddress}
        fullWidth
        variant="outlined"
        margin="normal"
        value={destinationAddress}
        onChange={(e) => setDestinationAddress(e.target.value.trim())}
        id={!validAddress ? 'outlined-error-helper-text' : undefined}
        error={!validAddress}
        InputProps={
          !isExtension
            ? {
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton color="inherit" onClick={presentQrScanner}>
                      <QrcodeIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }
            : undefined
        }
      />
      <TextField
        label={strings.amount}
        fullWidth
        variant="outlined"
        margin="normal"
        type="number"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <Button
                onClick={() =>
                  setTransferAmountString(balanceAmount.toFixed(9))
                }
              >
                {strings.max}
              </Button>
              <Typography color="primary">USDT</Typography>
            </InputAdornment>
          ),
          inputProps: {
            step: Math.pow(10, -9),
          },
        }}
        value={transferAmountString}
        onChange={(e) => setTransferAmountString(e.target.value.trim())}
        helperText={
          <span
            onClick={() => setTransferAmountString(balanceAmount.toFixed(9))}
          >
            {strings.max}: {balanceAmount.toFixed(9)}
          </span>
        }
      />
      {selectedNetwork === 'bsc' ? feeChildren : null}
      {scannerIsVisible &&
        createPortal(
          <div
            style={{
              margin: 8,
              paddingTop: 'env(safe-area-inset-top)',
            }}
          >
            <IconButton
              color="inherit"
              onClick={() => setScannerIsVisible(false)}
            >
              <Close />
            </IconButton>
          </div>,
          document.body,
        )}
    </>
  );

  return {
    fields,
    destinationAddress,
    transferAmountString,
    setDestinationAddress,
    validForm:
      (selectedNetwork !== 'bsc' || !isCalculatingFees) &&
      validAddress &&
      validAmount,
  };
}

function useFees({
  withdrawAddress,
  transferAmount,
  validFields,
  enabled,
}: {
  withdrawAddress: string;
  transferAmount: Decimal;
  validFields: boolean;
  enabled: boolean;
}) {
  const { data: tokenAccounts } = useTokenAccounts(USDT_PROGRAM_ID);

  const mintAccountsForTransfer = tokenAccounts
    ? findSuitableAccountsForTransfer(tokenAccounts, transferAmount)
    : [];

  const debouncedMintCount = useDebounce(mintAccountsForTransfer.length, 1000);

  const {
    data: estimateFeeData,
    isLoading,
    isFetched,
  } = useQuery({
    queryKey: ['usdtEstimateFee', debouncedMintCount, withdrawAddress],
    enabled: enabled && debouncedMintCount > 0,
    refetchInterval: 15000,
    queryFn: async ({ queryKey }) => {
      const response = await fetch(`${USDT_BRIDGE_URL}/estimate_fee`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
        body: JSON.stringify({
          withdraw_address: withdrawAddress,
          mint_count: queryKey[1],
        }),
      });

      const data = await response.json();
      console.debug('/estimate_fee data', data);

      if (data.status !== 'ok') {
        console.error('/estimate_fee data', data);
        if (data.status === 'error') {
          throw Error(data.message);
        }
        throw Error(strings.requestFailed);
      }

      return {
        fee: new Decimal(data.value),
        feeString: data.value,
      };
    },
  });

  const receiveAmount = estimateFeeData
    ? transferAmount.minus(estimateFeeData.fee)
    : transferAmount;

  const isCalculatingFees = !validFields && !isFetched ? null : isLoading;

  return {
    isCalculatingFees,
    children: isCalculatingFees ? (
      <Typography variant="body1" gutterBottom>
        {strings.loading}
      </Typography>
    ) : (
      estimateFeeData && (
        <>
          <Typography variant="body1" gutterBottom>
            {`${strings.expectedFee}: ${estimateFeeData.feeString} USDT`}
          </Typography>
          <Typography variant="body1" gutterBottom>
            {`${strings.youWillReceive}: ${receiveAmount?.toFixed(9)} USDT`}
          </Typography>
        </>
      )
    ),
  };
}

function usePerformTransferMutation(
  mutationFn: (
    data: Array<{
      account: TokenAccount;
      transferAmount: Decimal;
    }>,
  ) => Promise<unknown>,
) {
  const { data: tokenAccounts } = useTokenAccounts(USDT_PROGRAM_ID);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  return useMutation({
    mutationFn: async ({
      tokenPubkey,
      transferAmount,
    }: {
      tokenPubkey: PublicKey;
      transferAmount: Decimal;
    }) => {
      const id = enqueueSnackbar('Sending transaction...', {
        variant: 'info',
        persist: true,
      });

      try {
        const accounts = tokenPubkey
          ? tokenAccounts?.filter((account) =>
              account.pubkey.equals(tokenPubkey),
            )
          : tokenAccounts;

        if (!accounts) {
          throw new Error('No accounts found');
        }

        const suitableAccounts = findSuitableAccountsForTransfer(
          accounts,
          transferAmount,
        );

        if (suitableAccounts.length < 0) {
          throw new Error(
            `No suitable accounts found for transfer amount: ${transferAmount}`,
          );
        }

        await mutationFn(suitableAccounts);

        closeSnackbar(id);
        enqueueSnackbar('Transaction confirmed', {
          variant: 'success',
          autoHideDuration: 5000,
          // action: <ViewTransactionOnExplorerButton signature={signature} />,
        });
      } catch (err) {
        if (err instanceof Error) {
          closeSnackbar(id);
          enqueueSnackbar(err.message, { variant: 'error' });
          console.error(err);
        }
      }
    },
  });
}

function findSuitableAccountsForTransfer(
  accounts: TokenAccount[],
  transferAmount: Decimal,
) {
  const result: Array<{ account: TokenAccount; transferAmount: Decimal }> = [];
  const sortedAccounts = accounts
    .slice()
    .sort((a, b) =>
      Number(
        b.account.data.parsed.info.tokenAmount.uiAmount -
          a.account.data.parsed.info.tokenAmount.uiAmount,
      ),
    );

  let remainingTransferAmount = transferAmount;

  for (const account of sortedAccounts) {
    if (remainingTransferAmount.lessThanOrEqualTo(0)) {
      break;
    }
    const maxTransfer = Decimal.min(
      account.account.data.parsed.info.tokenAmount.uiAmountString,
      remainingTransferAmount,
    );
    result.push({ account, transferAmount: maxTransfer });
    remainingTransferAmount = remainingTransferAmount.sub(maxTransfer);
  }

  if (remainingTransferAmount.greaterThan(0.1)) {
    return [];
  }

  return result;
}

async function transferTokensBatched(
  connection: Connection,
  wallet: Wallet,
  accounts: Array<{ account: TokenAccount; transferAmount: Decimal }>,
  destinationPublicKey: PublicKey,
) {
  const signatures: Array<string> = [];

  let transaction = new Transaction();

  for (const { account, transferAmount } of accounts) {
    const transferTransaction = await transferTokens({
      connection,
      owner: wallet,
      sourcePublicKey: account.pubkey,
      destinationPublicKey,
      amount: BigInt(
        transferAmount
          .mul(10 ** account.account.data.parsed.info.tokenAmount.decimals)
          .ceil()
          .toString(),
      ),
      mint: new PublicKey(account.account.data.parsed.info.mint),
      decimals: account.account.data.parsed.info.tokenAmount.decimals,
      overrideDestinationCheck: true,
      programId: USDT_PROGRAM_ID,
      memo: null,
    });

    const estimatedTransactionSize = estimateTransactionSize(
      new Transaction().add(transaction).add(transferTransaction),
      wallet.publicKey,
    );

    if (estimatedTransactionSize < 1232) {
      transaction.add(transferTransaction);
      continue;
    }

    signatures.push(
      await signAndSendTransaction(connection, transaction, wallet, []),
    );

    transaction = transferTransaction;
  }

  signatures.push(
    await signAndSendTransaction(connection, transaction, wallet, []),
  );

  return signatures;
}

declare global {
  interface Window {
    QRScanner: any;
  }
}
