import { getDeviceUuid } from '../components/BalancesList/helpers';
import strings from '../localization';
import { TELEGRAM_BOT_BACKEND_URL, TELEGRAM_SUBSCRIBER_BOT_URL } from '../utils/env-variables';

export interface TelegramBotCheckResponse {
  hint: string;
  requestId: string;
  userId: number;
  accepted: boolean;
}

export default class TelegramBotService {
  async check(hint: string): Promise<TelegramBotCheckResponse> {
    const response = await fetch(`${TELEGRAM_BOT_BACKEND_URL}/load?hint=${hint}`);
    if (!response.ok) {
      throw new Error(strings.errorOccurredTryAgainLater);
    }

    // Example response:
    // {"requestId":"01927bd9-83be-7cc9-b45d-177ba6a55cf6","userId":123456789,"hint":"hint123","accepted":true}
    const responseBody = await response.json();
    const {
      hint: responseHint,
      accepted,
      userId,
    } = responseBody;
    if (hint !== responseHint || !accepted || !userId) {
      responseBody.accepted = false;
    }
    return responseBody;
  }

  createSubscriberBotUrl(hint: string): string {
    return `${TELEGRAM_SUBSCRIBER_BOT_URL}?start=${hint}`;
  }

  generateHint(): string {
    return `${getDeviceUuid()}`;
  }
}
