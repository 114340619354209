import React, { useEffect, useMemo, useState } from 'react';
import {
  generateMnemonicAndSeed,
  useHasLockedMnemonicAndSeed,
  loadMnemonicAndSeed,
  mnemonicToSeed,
  storeMnemonicAndSeed,
  normalizeMnemonic,
} from '../utils/wallet-seed';
import {
  getAccountFromSeed,
  DERIVATION_PATH,
} from '../utils/walletProvider/localStorage.js';
import Container from '@material-ui/core/Container';
import LoadingIndicator from '../components/LoadingIndicator';
import { BalanceListItem } from '../components/BalancesList.js';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import {
  DialogActions,
  DialogContentText,
  DialogTitle,
  Typography,
} from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Chip from '@material-ui/core/Chip';
import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';
import { useCallAsync } from '../utils/notifications';
import Link from '@material-ui/core/Link';
import { validateMnemonic } from 'bip39';
import DialogForm from '../components/DialogForm';
import strings from '../localization';
import { shuffleArray, isExtension, isInstalledPWA } from '../utils/utils';
import { Badge, Box, DialogContent } from '@mui/material';
import { AddBoxOutlined, Close, Share } from '@material-ui/icons';
import { DEBUG_SKIP_PWA_INSTALL } from '../utils/env-variables';

export default function LoginPage() {
  const [restore, setRestore] = useState(false);
  const [hasLockedMnemonicAndSeed, loading] = useHasLockedMnemonicAndSeed();

  if (loading) {
    return null;
  }

  if (
    !DEBUG_SKIP_PWA_INSTALL &&
    window.cordova?.platformId === 'browser' &&
    !isExtension &&
    !isInstalledPWA
  ) {
    return <InstallPWA />;
  }

  return (
    <Container maxWidth="sm">
      {restore ? (
        <RestoreWalletForm goBack={() => setRestore(false)} />
      ) : (
        <>
          {hasLockedMnemonicAndSeed ? <LoginForm /> : <CreateWalletForm />}
          <br />
          <Link style={{ cursor: 'pointer' }} onClick={() => setRestore(true)}>
            {strings.restoreExistingWallet}
          </Link>
        </>
      )}
    </Container>
  );
}

function InstallPWA() {
  const [supportsPWA, setSupportsPWA] = useState(false);
  const [promptInstall, setPromptInstall] = useState(null);

  useEffect(() => {
    const eventHandler = (event) => {
      event.preventDefault();

      setSupportsPWA(true);
      setPromptInstall(event);
    };

    window.addEventListener('beforeinstallprompt', eventHandler);
    return () => window.removeEventListener('transitionend', eventHandler);
  }, []);

  const onClick = (event) => {
    event.preventDefault();

    if (promptInstall) {
      promptInstall.prompt();
    }
  };

  return (
    <Card>
      <CardContent>
        <Typography variant="h5" gutterBottom>
          {strings.installPwaTitle}
        </Typography>
        <Typography paragraph>{strings.installPwaDescription}</Typography>

        {!supportsPWA && (
          <>
            <Typography>
              {strings.installPwaStepOne}{' '}
              <svg
                style={{ verticalAlign: 'middle' }}
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                width="24"
                height="24"
                viewBox="0 0 48 48"
                version="1.1"
              >
                <g
                  id="🔍-Product-Icons"
                  stroke="none"
                  strokeWidth="1"
                  fill="none"
                  fillRule="evenodd"
                >
                  <g
                    id="ic_fluent_share_ios_48_filled"
                    fill="#212121"
                    fillRule="nonzero"
                  >
                    <path
                      d="M37.75,20.25 C38.6681734,20.25 39.4211923,20.9571103 39.4941988,21.8564728 L39.5,22 L39.5,36.25 C39.5,39.3517853 37.0439828,41.879937 33.9705557,41.9958479 L33.75,42 L14.25,42 C11.1482147,42 8.62006299,39.5439828 8.50415208,36.4705557 L8.5,36.25 L8.5,22 C8.5,21.0335017 9.28350169,20.25 10.25,20.25 C11.1681734,20.25 11.9211923,20.9571103 11.9941988,21.8564728 L12,22 L12,36.25 C12,37.440864 12.9251616,38.4156449 14.0959512,38.4948092 L14.25,38.5 L33.75,38.5 C34.940864,38.5 35.9156449,37.5748384 35.9948092,36.4040488 L36,36.25 L36,22 C36,21.0335017 36.7835017,20.25 37.75,20.25 Z M23.4989075,6.26787884 L23.6477793,6.25297693 L23.6477793,6.25297693 L23.8225053,6.25140103 L23.8225053,6.25140103 L23.9770074,6.26441014 L23.9770074,6.26441014 L24.1549097,6.29667263 L24.1549097,6.29667263 L24.223898,6.31492315 L24.223898,6.31492315 C24.4192207,6.36884271 24.6069182,6.4577966 24.7773762,6.58126437 L24.8968901,6.67628678 L24.8968901,6.67628678 L24.989825,6.76256313 L32.7679996,14.5407377 C33.4514171,15.2241552 33.4514171,16.3321939 32.7679996,17.0156115 C32.1247831,17.6588279 31.1054316,17.6966642 30.4179639,17.1291203 L30.2931259,17.0156115 L25.5,12.222 L25.5,31.5 C25.5,32.4181734 24.7928897,33.1711923 23.8935272,33.2441988 L23.75,33.25 C22.8318266,33.25 22.0788077,32.5428897 22.0058012,31.6435272 L22,31.5 L22,12.226 L17.2116504,17.0156115 C16.5684339,17.6588279 15.5490824,17.6966642 14.8616148,17.1291203 L14.7367767,17.0156115 C14.0935602,16.372395 14.055724,15.3530435 14.6232679,14.6655758 L14.7367767,14.5407377 L22.488804,6.78678454 C22.5446792,6.72871358 22.6045271,6.67449255 22.6679103,6.62455868 L22.7812362,6.54379243 L22.7812362,6.54379243 C22.8189499,6.51724 22.858413,6.49312256 22.8988638,6.47056335 L22.9176605,6.46138558 C23.0947495,6.36422067 23.2909216,6.29776289 23.4989075,6.26787884 Z"
                      id="🎨-Color"
                    ></path>
                  </g>
                </g>
              </svg>
            </Typography>
            <Typography>
              {strings.installPwaStepTwo}{' '}
              <AddBoxOutlined style={{ verticalAlign: 'middle' }} />
            </Typography>
            <Typography>{strings.installPwaStepThree}</Typography>
          </>
        )}
      </CardContent>

      {supportsPWA && (
        <CardActions style={{ justifyContent: 'flex-end' }}>
          <Button color="primary" onClick={onClick}>
            {strings.installPwaTitle}
          </Button>
        </CardActions>
      )}
    </Card>
  );
}

function CreateWalletForm() {
  const [mnemonicAndSeed, setMnemonicAndSeed] = useState(null);
  useEffect(() => {
    generateMnemonicAndSeed().then(setMnemonicAndSeed);
  }, []);
  const [savedWords, setSavedWords] = useState(false);
  const callAsync = useCallAsync();

  function submit(password) {
    const { mnemonic, seed } = mnemonicAndSeed;
    callAsync(
      storeMnemonicAndSeed(
        mnemonic,
        seed,
        password,
        DERIVATION_PATH.bip44Change,
      ),
      {
        progressMessage: strings.creatingWallet,
        successMessage: strings.walletCreated,
      },
    );
  }

  if (!savedWords) {
    return (
      <SeedWordsForm
        mnemonicAndSeed={mnemonicAndSeed}
        goForward={() => setSavedWords(true)}
      />
    );
  }

  return (
    <ChoosePasswordForm
      mnemonicAndSeed={mnemonicAndSeed}
      goBack={() => setSavedWords(false)}
      onSubmit={submit}
    />
  );
}

function SeedWordsForm({ mnemonicAndSeed, goForward }) {
  const seedPhraseWords = useMemo(
    () => mnemonicAndSeed?.mnemonic?.split(' ') ?? [],
    [mnemonicAndSeed],
  );

  const shuffledSeedPhraseWords = useMemo(
    () => shuffleArray(seedPhraseWords),
    [seedPhraseWords],
  );

  const [confirmed, setConfirmed] = useState(false);
  const [downloaded, setDownloaded] = useState(!isExtension);
  const [showDialog, setShowDialog] = useState(false);
  const [confirmedWords, setConfirmedWords] = useState([]);

  const downloadMnemonic = (mnemonic) => {
    const url = window.URL.createObjectURL(new Blob([mnemonic]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'domichain_priv_key.bak');
    document.body.appendChild(link);
    link.click();
  };

  return (
    <>
      <Card>
        <CardContent>
          <Typography variant="h5" gutterBottom>
            {strings.createNewWallet}
          </Typography>
          <Typography paragraph>{strings.createToHold}.</Typography>
          <Typography>{strings.seedSafePlace}</Typography>
          <Box sx={{ paddingTop: 2, paddingBottom: 2 }}>
            {mnemonicAndSeed ? (
              <Box
                sx={{
                  width: '100%',
                  display: 'inline-grid',
                  gridTemplateRows: 'repeat(3, 1fr)',
                  gridTemplateColumns: 'repeat(3, 1fr)',
                  gap: 1,
                }}
              >
                {seedPhraseWords.map((word, index) => (
                  <Chip
                    key={index}
                    label={word}
                    color="primary"
                    style={{ width: '100%' }}
                    avatar={
                      <Avatar>{seedPhraseWords.indexOf(word) + 1}</Avatar>
                    }
                  />
                ))}
              </Box>
            ) : (
              <LoadingIndicator />
            )}
          </Box>
          <Typography paragraph>{strings.privateKeysStored}</Typography>
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                checked={confirmed}
                disabled={!mnemonicAndSeed}
                onChange={(e) => setConfirmed(e.target.checked)}
              />
            }
            label={strings.iHaveSaved}
          />
          {isExtension && (
            <Typography paragraph>
              <Button
                variant="contained"
                color="primary"
                style={{ marginTop: 20 }}
                onClick={() => {
                  downloadMnemonic(mnemonicAndSeed?.mnemonic);
                  setDownloaded(true);
                }}
              >
                {strings.downloadMnemonic}
              </Button>
            </Typography>
          )}
        </CardContent>
        <CardActions style={{ justifyContent: 'flex-end' }}>
          <Button
            color="primary"
            disabled={!confirmed || !downloaded}
            onClick={() => setShowDialog(true)}
          >
            {strings.continue}
          </Button>
        </CardActions>
      </Card>

      <DialogForm
        open={showDialog}
        onClose={() => {
          setShowDialog(false);
          setConfirmedWords([]);
        }}
        onSubmit={goForward}
        fullWidth
      >
        <DialogTitle>{strings.confirmMnenonic}</DialogTitle>
        <DialogContent>
          <DialogContentText>{strings.pleaseReenter}</DialogContentText>

          <Box
            sx={(theme) => ({
              height: 170,
              marginTop: 4,
              marginBottom: 4,
              border: `1px solid ${theme.palette.divider}`,
              borderRadius: 4,
              padding: 1,
            })}
          >
            <Box
              sx={{
                width: '100%',
                display: 'inline-grid',
                gridTemplateRows: 'repeat(3, 1fr)',
                gridTemplateColumns: 'repeat(3, 1fr)',
                gap: 1,
              }}
            >
              {confirmedWords.map((word, index) => {
                const isConfirmed =
                  confirmedWords.indexOf(word) ===
                  seedPhraseWords.indexOf(word);

                const handleDelete = () => {
                  setConfirmedWords((words) => words.filter((w) => w !== word));
                };

                return (
                  <Chip
                    key={index}
                    label={word}
                    style={{ width: '100%' }}
                    color={isConfirmed ? 'default' : 'secondary'}
                    onDelete={!isConfirmed ? handleDelete : undefined}
                    onClick={handleDelete}
                  />
                );
              })}
            </Box>
          </Box>

          <Box
            sx={{
              width: '100%',
              display: 'inline-grid',
              gridTemplateRows: 'repeat(3, 1fr)',
              gridTemplateColumns: 'repeat(3, 1fr)',
              gap: 1,
            }}
          >
            {shuffledSeedPhraseWords.map((word, index) => {
              const isSelected =
                seedPhraseWords.filter((w) => w === word).length ===
                confirmedWords.filter((confirmedWord) => confirmedWord === word)
                  .length;

              return (
                <Chip
                  key={index}
                  label={word}
                  style={{ width: '100%' }}
                  color={isSelected ? 'default' : 'primary'}
                  disabled={isSelected}
                  onClick={() => {
                    setConfirmedWords((words) => [...words, word]);
                  }}
                />
              );
            })}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setShowDialog(false);
              setConfirmedWords([]);
            }}
          >
            {strings.close}
          </Button>
          <Button
            type="submit"
            color="primary"
            disabled={confirmedWords.join(' ') !== mnemonicAndSeed?.mnemonic}
          >
            {strings.continue}
          </Button>
        </DialogActions>
      </DialogForm>
    </>
  );
}

function ChoosePasswordForm({ goBack, onSubmit }) {
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');

  return (
    <Card>
      <CardContent>
        <Typography variant="h5" gutterBottom>
          {strings.choosePassword}
        </Typography>
        <Typography>{strings.pickPassword}</Typography>
        <TextField
          variant="outlined"
          fullWidth
          margin="normal"
          label={strings.newPassword}
          type="password"
          autoComplete="new-password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <TextField
          variant="outlined"
          fullWidth
          margin="normal"
          label={strings.confirmPassword}
          type="password"
          autoComplete="new-password"
          value={passwordConfirm}
          onChange={(e) => setPasswordConfirm(e.target.value)}
        />
        <Typography>{strings.ifForgetPassword}</Typography>
      </CardContent>
      <CardActions style={{ justifyContent: 'space-between' }}>
        <Button onClick={goBack}>{strings.back}</Button>
        <Button
          color="primary"
          disabled={password !== passwordConfirm}
          onClick={() => onSubmit(password)}
        >
          {strings.createWallet}
        </Button>
      </CardActions>
    </Card>
  );
}

function LoginForm() {
  const [password, setPassword] = useState('');
  const [stayLoggedIn, setStayLoggedIn] = useState(false);
  const callAsync = useCallAsync();

  const submit = () => {
    callAsync(loadMnemonicAndSeed(password, stayLoggedIn), {
      progressMessage: strings.unlockingWallet,
      successMessage: strings.walletUnlocked,
    });
  };
  const submitOnEnter = (e) => {
    if (e.code === 'Enter' || e.code === 'NumpadEnter') {
      e.preventDefault();
      e.stopPropagation();
      submit();
    }
  };
  const setPasswordOnChange = (e) => setPassword(e.target.value);
  const toggleStayLoggedIn = (e) => setStayLoggedIn(e.target.checked);

  return (
    <Card>
      <CardContent>
        <Typography variant="h5" gutterBottom>
          {strings.unlockWallet}
        </Typography>
        <TextField
          variant="outlined"
          fullWidth
          margin="normal"
          label={strings.password}
          type="password"
          autoComplete="current-password"
          value={password}
          onChange={setPasswordOnChange}
          onKeyDown={submitOnEnter}
        />
        {false && (
          <FormControlLabel
            control={
              <Checkbox checked={stayLoggedIn} onChange={toggleStayLoggedIn} />
            }
            label={strings.keepWalletUnlocked}
          />
        )}
      </CardContent>
      <CardActions style={{ justifyContent: 'flex-end' }}>
        <Button color="primary" onClick={submit}>
          {strings.unlock}
        </Button>
      </CardActions>
    </Card>
  );
}

function RestoreWalletForm({ goBack }) {
  const [rawMnemonic, setRawMnemonic] = useState('');
  const [seed, setSeed] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [next, setNext] = useState(false);

  const mnemonic = normalizeMnemonic(rawMnemonic);
  const isNextBtnEnabled =
    password === passwordConfirm && validateMnemonic(mnemonic);
  const displayInvalidMnemonic =
    validateMnemonic(mnemonic) === false && mnemonic.length > 0;
  return (
    <>
      {next ? (
        <DerivedAccounts
          goBack={() => setNext(false)}
          mnemonic={mnemonic}
          password={password}
          seed={seed}
        />
      ) : (
        <Card>
          <CardContent>
            <Typography variant="h5" gutterBottom>
              {strings.restoreExisting}
            </Typography>
            <Typography>{strings.restoreExisting}</Typography>
            <br />
            <Typography fontWeight="fontWeightBold">
              {strings.noHardwareWallet}
            </Typography>
            {displayInvalidMnemonic && (
              <Typography fontWeight="fontWeightBold" style={{ color: 'red' }}>
                {strings.mnemonicFailed}
              </Typography>
            )}
            <TextField
              variant="outlined"
              fullWidth
              multiline
              rows={3}
              margin="normal"
              label={strings.seedWords}
              value={rawMnemonic}
              onChange={(e) => setRawMnemonic(e.target.value)}
            />
            <TextField
              variant="outlined"
              fullWidth
              margin="normal"
              label={strings.newPasswordOptional}
              type="password"
              autoComplete="new-password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <TextField
              variant="outlined"
              fullWidth
              margin="normal"
              label={strings.confirmPassword}
              type="password"
              autoComplete="new-password"
              value={passwordConfirm}
              onChange={(e) => setPasswordConfirm(e.target.value)}
            />
          </CardContent>
          <CardActions style={{ justifyContent: 'space-between' }}>
            <Button onClick={goBack}>{strings.cancel}</Button>
            <Button
              color="primary"
              disabled={!isNextBtnEnabled}
              onClick={() => {
                mnemonicToSeed(mnemonic).then((seed) => {
                  setSeed(seed);
                  setNext(true);
                });
              }}
            >
              {strings.next}
            </Button>
          </CardActions>
        </Card>
      )}
    </>
  );
}

function DerivedAccounts({ goBack, mnemonic, seed, password }) {
  const callAsync = useCallAsync();
  const [dPathMenuItem, setDPathMenuItem] = useState(
    DerivationPathMenuItem.Bip44Change,
  );
  const accounts = [...Array(10)].map((_, idx) => {
    return getAccountFromSeed(
      Buffer.from(seed, 'hex'),
      idx,
      toDerivationPath(dPathMenuItem),
    );
  });

  function submit() {
    callAsync(
      storeMnemonicAndSeed(
        mnemonic,
        seed,
        password,
        toDerivationPath(dPathMenuItem),
      ),
    );
  }

  return (
    <Card>
      <AccountsSelector
        showDeprecated={true}
        accounts={accounts}
        dPathMenuItem={dPathMenuItem}
        setDPathMenuItem={setDPathMenuItem}
      />
      <CardActions style={{ justifyContent: 'space-between' }}>
        <Button onClick={goBack}>{strings.back}</Button>
        <Button color="primary" onClick={submit}>
          Restore
        </Button>
      </CardActions>
    </Card>
  );
}

export function AccountsSelector({
  showRoot,
  showDeprecated,
  accounts,
  dPathMenuItem,
  setDPathMenuItem,
  onClick,
}) {
  return (
    <CardContent>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Typography variant="h5" gutterBottom>
          Derivable Accounts
        </Typography>
        <FormControl variant="outlined">
          <Select
            value={dPathMenuItem}
            onChange={(e) => {
              setDPathMenuItem(e.target.value);
            }}
          >
            {showRoot && (
              <MenuItem value={DerivationPathMenuItem.Bip44Root}>
                {`m/44'/501'`}
              </MenuItem>
            )}
            <MenuItem value={DerivationPathMenuItem.Bip44}>
              {`m/44'/501'/0'`}
            </MenuItem>
            <MenuItem value={DerivationPathMenuItem.Bip44Change}>
              {`m/44'/501'/0'/0'`}
            </MenuItem>
            {showDeprecated && (
              <MenuItem value={DerivationPathMenuItem.Deprecated}>
                {`m/501'/0'/0/0 (deprecated)`}
              </MenuItem>
            )}
          </Select>
        </FormControl>
      </div>
      {accounts.map((acc) => {
        return (
          <div onClick={onClick ? () => onClick(acc) : undefined}>
            <BalanceListItem
              key={acc.publicKey.toString()}
              onClick={onClick}
              publicKey={acc.publicKey}
              expandable={false}
            />
          </div>
        );
      })}
    </CardContent>
  );
}

// Material UI's Select doesn't render properly when using an `undefined` value,
// so we define this type and the subsequent `toDerivationPath` translator as a
// workaround.
//
// DERIVATION_PATH.deprecated is always undefined.
export const DerivationPathMenuItem = {
  Deprecated: 0,
  Bip44: 1,
  Bip44Change: 2,
  Bip44Root: 3, // Ledger only.
};

export function toDerivationPath(dPathMenuItem) {
  switch (dPathMenuItem) {
    case DerivationPathMenuItem.Deprecated:
      return DERIVATION_PATH.deprecated;
    case DerivationPathMenuItem.Bip44:
      return DERIVATION_PATH.bip44;
    case DerivationPathMenuItem.Bip44Change:
      return DERIVATION_PATH.bip44Change;
    case DerivationPathMenuItem.Bip44Root:
      return DERIVATION_PATH.bip44Root;
    default:
      throw new Error(`invalid derivation path: ${dPathMenuItem}`);
  }
}
