import { Typography } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Link from '@material-ui/core/Link';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Paper from '@material-ui/core/Paper';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/AddCircle';
import AirdropIcon from '@material-ui/icons/AirplanemodeActive';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import RefreshIcon from '@material-ui/icons/Refresh';
import SendIcon from '@material-ui/icons/Send';
import StarIcon from '@material-ui/icons/Star';
import SwapIcon from '@material-ui/icons/SwapVert';
import ReceiveIcon from '@material-ui/icons/WorkOutline';
import { useQuery } from '@tanstack/react-query';
import { memo, useCallback, useReducer, useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { match } from 'ts-pattern';
import strings from '../localization';
import { showSwapAddress } from '../utils/config';
import {
  refreshAccountInfo,
  useConnection,
  useDomichainExplorerUrlSuffix,
  useIsProdNetwork,
} from '../utils/connection';
import { EXPLORER_URL } from '../utils/env-variables';
import { serumMarkets } from '../utils/markets';
import { swapApiRequest } from '../utils/swap/api';
import { findAssociatedTokenAddress } from '../utils/tokens';
import { useTokenAccounts } from '../utils/tokens/hooks';
import {
  BTCI_PROGRAM_ID,
  TOKEN_PROGRAM_ID,
  USDT_PROGRAM_ID,
} from '../utils/tokens/instructions';
import {
  abbreviateAddress,
  isExtension,
  shortenAddress,
  useIsExtensionWidth,
} from '../utils/utils';
import {
  refreshWalletPublicKeys,
  useBalanceInfo,
  useWallet,
  useWalletPublicKeys,
  useWalletSelector,
} from '../utils/wallet';
import AddTokenDialog from './AddTokenDialog';
import CloseTokenAccountDialog from './CloseTokenAccountButton';
import DepositDialog from './DepositDialog';
import EditAccountNameDialog from './EditAccountNameDialog';
import ExportAccountDialog from './ExportAccountDialog';
import FtxPayDialog from './FtxPay/FtxPayDialog';
import LoadingIndicator from './LoadingIndicator';
import MergeAccountsDialog from './MergeAccountsDialog';
import MintTokenDialog from './MintTokenDialog';
import { RedPacket } from './RedPacket';
import SendDialog from './SendDialog';
import TokenIcon from './TokenIcon';
import TokenInfoDialog from './TokenInfoDialog';
import { WelcomeAirdrop } from './WelcomeAirdrop';
import BtcDepositDialog from './bridge/bitcoin/BtcDepositDialog';
import BtcSendDialog from './bridge/bitcoin/BtcSendDialog';
import UsdtDepositDialog from './bridge/usdt/UsdtDepositDialog';
import UsdtExchangeDialog from './bridge/usdt/UsdtExchangeDialog';
import UsdtSendDialog from './bridge/usdt/UsdtSendDialog';
import StakeDialog from './staking/StakeDialog';
import BuyDomiListItem from './BalancesList/BuyDomiListItem';
import TelegramSubscribeDialog from './BalancesList/TelegramSubscribeDialog';

const SortAccounts = {
  None: 0,
  Ascending: 1,
  Descending: 2,
};

// Aggregated $USD values of all child BalanceListItems child components.
//
// Values:
// * undefined => loading.
// * null => no market exists.
// * float => done.
//
// For a given set of publicKeys, we know all the USD values have been loaded when
// all of their values in this object are not `undefined`.
const usdValues = {};

// Calculating associated token addresses is an asynchronous operation, so we cache
// the values so that we can quickly render components using them. This prevents
// flickering for the associated token fingerprint icon.
const associatedTokensCache = {};

const numberFormat = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

function fairsIsLoaded(publicKeys) {
  return (
    publicKeys.filter((pk) => usdValues[pk.toString()] !== undefined).length ===
    publicKeys.length
  );
}

export default function BalancesList({ onRefreshClick }) {
  const wallet = useWallet();
  const [publicKeys, loaded] = useWalletPublicKeys();
  const [showAddTokenDialog, setShowAddTokenDialog] = useState(false);
  const [showEditAccountNameDialog, setShowEditAccountNameDialog] =
    useState(false);
  const [showTelegramSubscribeDialog, setShowTelegramSubscribeDialog] =
    useState(false);
  const [showMergeAccounts, setShowMergeAccounts] = useState(false);
  const [showFtxPayDialog, setShowFtxPayDialog] = useState(false);
  const [sortAccounts, setSortAccounts] = useState(SortAccounts.None);
  const [showDomains, setShowDomains] = useState(false);
  const { accounts, setAccountName } = useWalletSelector();
  const [isCopied, setIsCopied] = useState(false);
  const isExtensionWidth = useIsExtensionWidth();
  // Dummy var to force rerenders on demand.
  const [, setForceUpdate] = useState(false);
  const selectedAccount = accounts.find((a) => a.isSelected);
  const allTokensLoaded = loaded && fairsIsLoaded(publicKeys);
  let sortedPublicKeys = publicKeys;
  if (allTokensLoaded && sortAccounts !== SortAccounts.None) {
    sortedPublicKeys = [...publicKeys];
    sortedPublicKeys.sort((a, b) => {
      const aVal = usdValues[a.toString()];
      const bVal = usdValues[b.toString()];

      a = aVal === undefined || aVal === null ? -1 : aVal;
      b = bVal === undefined || bVal === null ? -1 : bVal;
      if (sortAccounts === SortAccounts.Descending) {
        if (a < b) {
          return -1;
        } else if (a > b) {
          return 1;
        } else {
          return 0;
        }
      } else {
        if (b < a) {
          return -1;
        } else if (b > a) {
          return 1;
        } else {
          return 0;
        }
      }
    });
  }
  const totalUsdValue = publicKeys
    .filter((pk) => usdValues[pk.toString()])
    .map((pk) => usdValues[pk.toString()])
    .reduce((a, b) => a + b, 0.0);

  // Memoized callback and component for the `BalanceListItems`.
  //
  // The `BalancesList` fetches data, e.g., fairs for tokens using React hooks
  // in each of the child `BalanceListItem` components. However, we want the
  // parent component, to aggregate all of this data together, for example,
  // to show the cumulative USD amount in the wallet.
  //
  // To achieve this, we need to pass a callback from the parent to the child,
  // so that the parent can collect the results of all the async network requests.
  // However, this can cause a render loop, since invoking the callback can cause
  // the parent to rerender, which causes the child to rerender, which causes
  // the callback to be invoked.
  //
  // To solve this, we memoize all the `BalanceListItem` children components.
  const setUsdValuesCallback = useCallback(
    (publicKey, usdValue) => {
      if (usdValues[publicKey.toString()] !== usdValue) {
        usdValues[publicKey.toString()] = usdValue;
        if (fairsIsLoaded(publicKeys)) {
          setForceUpdate((forceUpdate) => !forceUpdate);
        }
      }
    },
    [publicKeys],
  );
  const [showMintDialog, setShowMintDialog] = useState(false);
  const [mindDialogId, bumpMindDialogId] = useReducer((x) => x + 1, 0);
  const [usdtDepositDialogOpen, setUsdtDepositDialogOpen] = useState(false);
  const [usdtExchangeDialogOpen, setUsdtExchangeDialogOpen] = useState(false);
  const { data: usdtTokenAccounts } = useTokenAccounts(USDT_PROGRAM_ID);

  const iconSize = isExtensionWidth ? 'small' : 'medium';

  const onBuyDomiClick = () => {
    const usdtTotalAmount =
      usdtTokenAccounts?.reduce((acc, curr) => {
        const tokenAmount = curr.account.data.parsed.info.tokenAmount;
        return acc + tokenAmount.uiAmount;
      }, 0) ?? 0;

    if (usdtTotalAmount === 0) {
      setUsdtDepositDialogOpen(true);
    } else {
      setUsdtExchangeDialogOpen(true);
    }
  };

  return (
    <Paper>
      <AppBar position="static" color="default" elevation={1}>
        <Toolbar>
          <CopyToClipboard
            text={selectedAccount && selectedAccount.address.toBase58()}
            onCopy={() => {
              setIsCopied(true);
              setTimeout(() => {
                setIsCopied(false);
              }, 1000);
            }}
          >
            <Tooltip
              title={
                <Typography>
                  {isCopied ? strings.copied : strings.copyToClipboard}
                </Typography>
              }
              style={{ fontSize: '10rem' }}
            >
              <Typography
                variant="h6"
                style={{
                  flexGrow: 1,
                  fontSize: isExtensionWidth && '1rem',
                  cursor: 'pointer',
                }}
                hover={true}
                component="h2"
              >
                {selectedAccount && selectedAccount.name}
                {isExtensionWidth
                  ? ''
                  : ` (${
                      selectedAccount &&
                      shortenAddress(selectedAccount.address.toBase58())
                    })`}{' '}
                {/* {allTokensLoaded && ( */}
                {/* <>({numberFormat.format(totalUsdValue.toFixed(2))})</> */}
                {/* )} */}
              </Typography>
            </Tooltip>
          </CopyToClipboard>
          {selectedAccount &&
            selectedAccount.name !== strings.mainAccount &&
            selectedAccount.name !== 'Hardware wallet' && (
              <Tooltip title={strings.editAccountName} arrow>
                <IconButton
                  size={iconSize}
                  onClick={() => setShowEditAccountNameDialog(true)}
                >
                  <EditIcon />
                </IconButton>
              </Tooltip>
            )}
          {/* <Tooltip title="Deposit via FTX Pay" arrow>
            <IconButton
              size={iconSize}
              onClick={() => setShowFtxPayDialog(true)}
            >
              <img
                title={'FTX Pay'}
                alt={'FTX Pay'}
                style={{
                  width: 20,
                  height: 20,
                }}
                src={ftxPayIcon}
              />
            </IconButton>
          </Tooltip>
          <Tooltip title="See your domains" arrow>
            <IconButton size={iconSize} onClick={() => setShowDomains(true)}>
              <DnsIcon />
            </IconButton>
          </Tooltip>
          <DomainsList open={showDomains} setOpen={setShowDomains} />
          {region.result && !region.result.isRestricted && <SwapButton size={iconSize} />}
          <Tooltip title="Migrate Tokens" arrow>
            <IconButton
              size={iconSize}
              onClick={() => setShowMergeAccounts(true)}
            >
              <MergeType />
            </IconButton>
          </Tooltip>
          <Tooltip title="Add Token" arrow>
            <IconButton
              size={iconSize}
              onClick={() => setShowAddTokenDialog(true)}
            >
              <AddIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Sort Tokens" arrow>
            <IconButton
              size={iconSize}
              onClick={() => {
                switch (sortAccounts) {
                  case SortAccounts.None:
                    setSortAccounts(SortAccounts.Ascending);
                    return;
                  case SortAccounts.Ascending:
                    setSortAccounts(SortAccounts.Descending);
                    return;
                  case SortAccounts.Descending:
                    setSortAccounts(SortAccounts.None);
                    return;
                  default:
                    console.error('invalid sort type', sortAccounts);
                }
              }}
            >
              <SortIcon />
            </IconButton>
          </Tooltip> */}
          <Tooltip title={strings.refresh} arrow>
            <IconButton
              size={iconSize}
              onClick={() => {
                console.log('refresh');
                onRefreshClick();
                refreshWalletPublicKeys(wallet);
                publicKeys.map((publicKey) =>
                  refreshAccountInfo(wallet.connection, publicKey),
                );
              }}
              style={{ marginRight: -12 }}
            >
              <RefreshIcon />
            </IconButton>
          </Tooltip>
        </Toolbar>
      </AppBar>
      <List disablePadding>
        <BalanceListItem
          key={wallet.publicKey}
          publicKey={wallet.publicKey}
          setUsdValue={setUsdValuesCallback}
        />
        <BridgeTokenBalanceListItem
          tokenName="BTC"
          tokenImageUrl="https://cryptologos.cc/logos/bitcoin-btc-logo.png"
          programId={BTCI_PROGRAM_ID}
        />
        <BridgeTokenBalanceListItem
          tokenName="USDT"
          tokenImageUrl="https://cryptologos.cc/logos/tether-usdt-logo.png"
          programId={USDT_PROGRAM_ID}
        />
        {sortedPublicKeys.map((pk) => (
          <BalanceListItem
            key={pk.toString()}
            publicKey={pk}
            setUsdValue={setUsdValuesCallback}
          />
        ))}
        {loaded && window.cordova?.platformId !== 'ios' ? (
          <ListItem
            button
            onClick={() => {
              bumpMindDialogId();
              setShowMintDialog(true);
            }}
          >
            <ListItemIcon>
              <AddIcon size={iconSize} />
            </ListItemIcon>
            <ListItemText primary={strings.mintNewToken} />
          </ListItem>
        ) : null}
        {loaded ? null : <LoadingIndicator />}
        <WelcomeAirdrop onShowSubscription={() => setShowTelegramSubscribeDialog(true)} />
        <BuyDomiListItem iconSize={iconSize} onClick={() => onBuyDomiClick()} />
        <RedPacket />
      </List>
      {!showTelegramSubscribeDialog ? null : <TelegramSubscribeDialog
        open={showTelegramSubscribeDialog}
        onClose={() => setShowTelegramSubscribeDialog(false)}
      />}
      <AddTokenDialog
        open={showAddTokenDialog}
        onClose={() => setShowAddTokenDialog(false)}
      />
      <FtxPayDialog
        open={showFtxPayDialog}
        publicKeys={publicKeys}
        onClose={() => setShowFtxPayDialog(false)}
      />
      <EditAccountNameDialog
        open={showEditAccountNameDialog}
        onClose={() => setShowEditAccountNameDialog(false)}
        oldName={selectedAccount ? selectedAccount.name : ''}
        onEdit={(name) => {
          setAccountName(selectedAccount.selector, name);
          setShowEditAccountNameDialog(false);
        }}
      />
      <MergeAccountsDialog
        open={showMergeAccounts}
        onClose={() => setShowMergeAccounts(false)}
      />
      <MintTokenDialog
        key={mindDialogId}
        open={showMintDialog}
        onClose={() => {
          onRefreshClick();
          setShowMintDialog(false);
          refreshWalletPublicKeys(wallet);
          publicKeys.map((publicKey) =>
            refreshAccountInfo(wallet.connection, publicKey),
          );
        }}
      />
      <UsdtDepositDialog
        open={usdtDepositDialogOpen}
        onClose={() => setUsdtDepositDialogOpen(false)}
      />
      <UsdtExchangeDialog
        open={usdtExchangeDialogOpen}
        onClose={() => setUsdtExchangeDialogOpen(false)}
      />
    </Paper>
  );
}

// Format trailing zeros 0.000 -> 0.0
function removeTrailingZeros(formattedAmount) {
  if (formattedAmount.indexOf('.') === -1) {
    return formattedAmount;
  }
  while (formattedAmount.slice(-1) === '0') {
    formattedAmount = formattedAmount.slice(0, formattedAmount.length - 1);
  }
  if (formattedAmount.slice(-1) === '.') {
    return `${formattedAmount}0`;
  }
  return formattedAmount;
}

function balanceAmountToTrailingUserAmount(balanceAmount, decimals) {
  const balanceFormat = new Intl.NumberFormat(undefined, {
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals,
    useGrouping: true,
  });
  const formattedAmount = balanceFormat.format(
    balanceAmount / Math.pow(10, decimals),
  );
  return removeTrailingZeros(formattedAmount);
}

export const BalanceListItem = memo(
  ({ publicKey, expandable, setUsdValue, programId = TOKEN_PROGRAM_ID }) => {
    const wallet = useWallet();
    const balanceInfo = useBalanceInfo(publicKey);
    const classes = useStyles();
    const connection = useConnection();
    const [open, setOpen] = useState(false);
    const isExtensionWidth = useIsExtensionWidth();
    const [, setForceUpdate] = useState(false);
    // Valid states:
    //   * undefined => loading.
    //   * null => not found.
    //   * else => price is loaded.
    const [price, setPrice] = useState(undefined);
    // useEffect(() => {
    //   if (balanceInfo) {
    //     if (balanceInfo.tokenSymbol) {
    //       const coin = balanceInfo.tokenSymbol.toUpperCase();
    //       // Don't fetch USD stable coins. Mark to 1 USD.
    //       if (coin === 'USDT' || coin === 'USDC') {
    //         setPrice(1);
    //       }
    //       // A Serum market exists. Fetch the price.
    //       else if (serumMarkets[coin]) {
    //         let m = serumMarkets[coin];
    //         priceStore
    //           .getPrice(connection, m.name)
    //           .then((price) => {
    //             setPrice(price);
    //           })
    //           .catch((err) => {
    //             console.error(err);
    //             setPrice(null);
    //           });
    //       }
    //       // No Serum market exists.
    //       else {
    //         setPrice(null);
    //       }
    //     }
    //     // No token symbol so don't fetch market data.
    //     else {
    //       setPrice(null);
    //     }
    //   }
    // }, [price, balanceInfo, connection]);

    expandable = expandable === undefined ? true : expandable;

    if (!balanceInfo) {
      return <LoadingIndicator delay={0} />;
    }

    let { amount, decimals, mint, tokenName, tokenSymbol, tokenLogoUri } =
      balanceInfo;

    tokenName = tokenName ?? abbreviateAddress(mint);
    let displayName;
    if (isExtensionWidth) {
      displayName = tokenSymbol ?? tokenName;
    } else {
      displayName = tokenName + (tokenSymbol ? ` (${tokenSymbol})` : '');
    }

    // Fetch and cache the associated token address.
    if (wallet && wallet.publicKey && mint) {
      if (
        associatedTokensCache[wallet.publicKey.toString()] === undefined ||
        associatedTokensCache[wallet.publicKey.toString()][mint.toString()] ===
          undefined
      ) {
        const assocTok = findAssociatedTokenAddress(
          wallet.publicKey,
          mint,
          programId,
        );
        const walletAccounts = Object.assign(
          {},
          associatedTokensCache[wallet.publicKey.toString()],
        );
        walletAccounts[mint.toString()] = assocTok;
        associatedTokensCache[wallet.publicKey.toString()] = walletAccounts;
        if (assocTok.equals(publicKey)) {
          // Force a rerender now that we've cached the value.
          setForceUpdate((forceUpdate) => !forceUpdate);
        }
      }
    }

    // undefined => not loaded.
    let isAssociatedToken = mint ? undefined : false;
    if (
      wallet &&
      wallet.publicKey &&
      mint &&
      associatedTokensCache[wallet.publicKey.toString()]
    ) {
      let acc =
        associatedTokensCache[wallet.publicKey.toString()][mint.toString()];
      if (acc) {
        if (acc.equals(publicKey)) {
          isAssociatedToken = true;
        } else {
          isAssociatedToken = false;
        }
      }
    }

    const subtitle =
      isExtensionWidth || !publicKey.equals(balanceInfo.owner) ? undefined : (
        <div style={{ display: 'flex', height: '20px', overflow: 'hidden' }}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
            }}
          >
            {publicKey.toBase58()}
          </div>
        </div>
      );

    const usdValue =
      price === undefined // Not yet loaded.
        ? undefined
        : price === null // Loaded and empty.
        ? null
        : ((amount / Math.pow(10, decimals)) * price).toFixed(2); // Loaded.
    if (setUsdValue && usdValue !== undefined) {
      setUsdValue(publicKey, usdValue === null ? null : parseFloat(usdValue));
    }

    return (
      <>
        <ListItem button onClick={() => expandable && setOpen((open) => !open)}>
          <ListItemIcon>
            <TokenIcon
              mint={mint}
              tokenName={tokenName}
              url={tokenLogoUri}
              size={28}
            />
          </ListItemIcon>
          <div style={{ display: 'flex', flex: 1 }}>
            <ListItemText
              primary={
                <>
                  {balanceAmountToTrailingUserAmount(amount, decimals)}{' '}
                  {displayName}
                </>
              }
              secondary={subtitle}
              secondaryTypographyProps={{ className: classes.address }}
            />
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
              }}
            >
              {price && (
                <Typography color="textSecondary">
                  {numberFormat.format(usdValue)}
                </Typography>
              )}
            </div>
          </div>
          {expandable && (open ? <ExpandLess /> : <ExpandMore />)}
        </ListItem>
        {expandable && (
          <Collapse in={open} timeout="auto" unmountOnExit>
            <BalanceListItemDetails
              isAssociatedToken={isAssociatedToken}
              publicKey={publicKey}
              serumMarkets={serumMarkets}
              balanceInfo={balanceInfo}
              programId={programId}
            />
          </Collapse>
        )}
      </>
    );
  },
);

const BalanceListItemDetails = memo(
  ({ publicKey, serumMarkets, balanceInfo, isAssociatedToken, programId }) => {
    const urlSuffix = useDomichainExplorerUrlSuffix();
    const classes = useStyles();
    const [sendDialogOpen, setSendDialogOpen] = useState(false);
    const [depositDialogOpen, setDepositDialogOpen] = useState(false);
    const [tokenInfoDialogOpen, setTokenInfoDialogOpen] = useState(false);
    const [exportAccDialogOpen, setExportAccDialogOpen] = useState(false);
    const [closeTokenAccountDialogOpen, setCloseTokenAccountDialogOpen] =
      useState(false);
    const [showStakeDialog, setShowStakeDialog] = useState(false);
    const [showDetails, setShowDetails] = useState(false);
    const wallet = useWallet();
    const isProdNetwork = useIsProdNetwork();
    const { data: swapInfo } = useQuery({
      queryKey: [
        'swapInfo',
        isProdNetwork,
        balanceInfo.mint?.toBase58(),
        publicKey.toBase58(),
      ],
      queryFn: async () => {
        if (!showSwapAddress || !isProdNetwork) {
          return null;
        }
        return await swapApiRequest(
          'POST',
          'swap_to',
          {
            blockchain: 'domi',
            coin: balanceInfo.mint?.toBase58(),
            address: publicKey.toBase58(),
          },
          { ignoreUserErrors: true },
        );
      },
    });

    const isExtensionWidth = useIsExtensionWidth();

    if (!balanceInfo) {
      return <LoadingIndicator delay={0} />;
    }

    let { mint, tokenName, tokenSymbol, owner, amount } = balanceInfo;

    // Only show the export UI for the native DOMI coin.
    const exportNeedsDisplay =
      mint === null && tokenName === 'DOMI' && tokenSymbol === 'DOMI';

    const market = tokenSymbol
      ? serumMarkets[tokenSymbol.toUpperCase()]
        ? serumMarkets[tokenSymbol.toUpperCase()].publicKey
        : undefined
      : undefined;
    const isSolAddress = publicKey.equals(owner);
    const additionalInfo = isExtensionWidth ? undefined : (
      <>
        <Typography variant="body2">
          {strings.tokenName}: {tokenName ?? 'Unknown'}
        </Typography>
        <Typography variant="body2">
          {strings.tokenSymbol}: {tokenSymbol ?? 'Unknown'}
        </Typography>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div>
            {!isSolAddress && isAssociatedToken === false && (
              <div style={{ display: 'flex' }}>{strings.auxiliaryAccount}</div>
            )}
            <Typography variant="body2">
              <Link
                href={
                  `${EXPLORER_URL}/account/${publicKey.toBase58()}` + urlSuffix
                }
                target="_blank"
                rel="noopener"
              >
                {strings.viewOnDomiexplorer}
              </Link>
            </Typography>
            {market && (
              <Typography variant="body2">
                <Link
                  href={`https://dex.projectserum.com/#/market/${market}`}
                  target="_blank"
                  rel="noopener"
                >
                  View on Serum
                </Link>
              </Typography>
            )}
            {swapInfo && swapInfo.coin.erc20Contract && (
              <Typography variant="body2">
                <Link
                  href={
                    `https://etherscan.io/token/${swapInfo.coin.erc20Contract}` +
                    urlSuffix
                  }
                  target="_blank"
                  rel="noopener"
                >
                  View on Ethereum
                </Link>
              </Typography>
            )}
            {!isSolAddress && (
              <Typography variant="body2">
                <Link
                  className={classes.viewDetails}
                  onClick={() => setShowDetails(!showDetails)}
                >
                  {strings.viewDetails}
                </Link>
              </Typography>
            )}
            {showDetails &&
              (mint ? (
                <Typography variant="body2" className={classes.address}>
                  Mint Address: {mint.toBase58()}
                </Typography>
              ) : null)}
            {!isSolAddress && showDetails && (
              <Typography variant="body2" className={classes.address}>
                {isAssociatedToken ? 'Associated' : ''} Token Metadata:{' '}
                {publicKey.toBase58()}
              </Typography>
            )}
          </div>
          {exportNeedsDisplay && wallet.allowsExport && (
            <div>
              <Typography variant="body2">
                <Link href={'#'} onClick={(e) => setExportAccDialogOpen(true)}>
                  {strings.export}
                </Link>
              </Typography>
            </div>
          )}
        </div>
      </>
    );

    const openAirdropDapp = () => {
      const url = encodeURI(
        `https://ad.domichain.io/?recipient=${publicKey.toBase58()}`,
      );

      if (isExtension) {
        window.open(url, '_blank');
        return;
      }

      let settings = {
        location: true,
        fullscreen: false,
        toolbarcolor: '#2196f3',
        navigationbuttoncolor: '#ffffff',
        closebuttoncolor: '#ffffff',
      };

      if (window.cordova?.platformId === 'ios') {
        settings.toolbarposition = 'top';
        delete settings.toolbarcolor;
      }

      const settingsString = Object.entries(settings)
        .map(([key, value]) => {
          const serializedValue =
            typeof value === 'boolean' ? (value ? 'yes' : 'no') : value;
          return `${key}=${serializedValue}`;
        })
        .join(',');

      window.cordova.InAppBrowser.open(url, '_blank', settingsString);
    };

    return (
      <>
        {wallet.allowsExport && (
          <ExportAccountDialog
            onClose={() => setExportAccDialogOpen(false)}
            open={exportAccDialogOpen}
          />
        )}
        <div className={classes.itemDetails}>
          <div className={classes.buttonContainer}>
            {programId.equals(TOKEN_PROGRAM_ID) && (
              <Button
                variant="outlined"
                color="primary"
                startIcon={<ReceiveIcon />}
                onClick={() => setDepositDialogOpen(true)}
              >
                {strings.receive}
              </Button>
            )}
            <Button
              variant="outlined"
              color="primary"
              startIcon={<SendIcon />}
              onClick={() => setSendDialogOpen(true)}
            >
              {strings.send}
            </Button>
            {window.cordova?.platformId !== 'ios' &&
              publicKey.equals(owner) && (
                <Button
                  variant="outlined"
                  color="primary"
                  startIcon={<StarIcon />}
                  onClick={() => setShowStakeDialog(true)}
                >
                  {strings.stake}
                </Button>
              )}
            {window.cordova?.platformId !== 'ios' &&
              publicKey.equals(owner) && (
                <Button
                  variant="outlined"
                  color="primary"
                  startIcon={<AirdropIcon />}
                  onClick={openAirdropDapp}
                >
                  {strings.airdrop}
                </Button>
              )}
            {/* {!isExtension && !publicKey.equals(owner) && (
              <Button
                variant="outlined"
                color="primary"
                startIcon={<OpenInNewIcon />}
                href={
                  `${
                    EXPLORER_URL
                  }/account/${publicKey.toBase58()}` + urlSuffix
                }
                target="_blank"
                rel="noopener"
              >
                {strings.viewOnDomiexplorer}
              </Button>
            )} */}
            {!publicKey.equals(owner) && (
              <Button
                variant="outlined"
                color="primary"
                startIcon={<InfoIcon />}
                onClick={() => setTokenInfoDialogOpen(true)}
              >
                Token Info
              </Button>
            )}
            {localStorage.getItem('warning-close-account') &&
            mint &&
            amount === 0 ? (
              <Button
                variant="outlined"
                color="primary"
                size="small"
                startIcon={<DeleteIcon />}
                onClick={() => setCloseTokenAccountDialogOpen(true)}
              >
                {strings.delete}
              </Button>
            ) : null}
          </div>
          {/* {additionalInfo} */}
        </div>
        {match(programId)
          .with(BTCI_PROGRAM_ID, () => (
            <BtcSendDialog
              open={sendDialogOpen}
              onClose={() => setSendDialogOpen(false)}
              publicKey={publicKey}
            />
          ))
          .with(USDT_PROGRAM_ID, () => (
            <UsdtSendDialog
              open={sendDialogOpen}
              onClose={() => setSendDialogOpen(false)}
              publicKey={publicKey}
            />
          ))
          .otherwise(() => (
            <SendDialog
              open={sendDialogOpen}
              onClose={() => setSendDialogOpen(false)}
              balanceInfo={balanceInfo}
              publicKey={publicKey}
              programId={programId}
            />
          ))}
        <DepositDialog
          open={depositDialogOpen}
          onClose={() => setDepositDialogOpen(false)}
          balanceInfo={balanceInfo}
          publicKey={publicKey}
          swapInfo={swapInfo}
          isAssociatedToken={isAssociatedToken}
        />
        <TokenInfoDialog
          open={tokenInfoDialogOpen}
          onClose={() => setTokenInfoDialogOpen(false)}
          balanceInfo={balanceInfo}
          publicKey={publicKey}
        />
        <CloseTokenAccountDialog
          open={closeTokenAccountDialogOpen}
          onClose={() => setCloseTokenAccountDialogOpen(false)}
          balanceInfo={balanceInfo}
          publicKey={publicKey}
        />
        <StakeDialog
          open={showStakeDialog}
          onClose={() => setShowStakeDialog(false)}
        />
      </>
    );
  },
);

export const BridgeTokenBalanceListItem = memo(
  ({ tokenName, tokenImageUrl, programId }) => {
    const [open, setOpen] = useState(false);
    const { data: tokenAccounts } = useTokenAccounts(programId);

    const totalAmount =
      tokenAccounts?.reduce((acc, curr) => {
        const tokenAmount = curr.account.data.parsed.info.tokenAmount;
        return acc + tokenAmount.uiAmount;
      }, 0) ?? 0;

    const decimals =
      Array.isArray(tokenAccounts) && tokenAccounts.length > 0
        ? tokenAccounts[0].account.data.parsed.info.tokenAmount.decimals
        : 9;

    return (
      <>
        <ListItem button onClick={() => setOpen((open) => !open)}>
          <ListItemIcon>
            <TokenIcon tokenName={tokenName} url={tokenImageUrl} size={28} />
          </ListItemIcon>
          <div style={{ display: 'flex', flex: 1 }}>
            <ListItemText
              primary={`${removeTrailingZeros(
                totalAmount.toFixed(decimals),
              )} ${tokenName}`}
            />
          </div>
          {open ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <Box sx={{ bgcolor: 'grey.100' }}>
            <BridgeTokenBalanceListItemDetails
              tokenAccounts={tokenAccounts}
              programId={programId}
              // decimals={decimals}
            />
          </Box>
        </Collapse>
      </>
    );
  },
);

const BridgeTokenBalanceListItemDetails = memo(
  ({ tokenAccounts, programId }) => {
    const classes = useStyles();
    const [sendDialogOpen, setSendDialogOpen] = useState(false);
    const [depositDialogOpen, setDepositDialogOpen] = useState(false);
    const [exchangeDialogOpen, setExchangeDialogOpen] = useState(false);

    return (
      <>
        <div className={classes.itemDetails}>
          <div className={classes.buttonContainer}>
            <Button
              variant="outlined"
              color="primary"
              startIcon={<ReceiveIcon />}
              onClick={() => setDepositDialogOpen(true)}
            >
              {strings.receive}
            </Button>
            <Button
              variant="outlined"
              color="primary"
              startIcon={<SendIcon />}
              onClick={() => setSendDialogOpen(true)}
            >
              {strings.send}
            </Button>
            {programId.equals(USDT_PROGRAM_ID) && (
              <Button
                variant="outlined"
                color="primary"
                startIcon={<SwapIcon />}
                onClick={() => setExchangeDialogOpen(true)}
              >
                {strings.buyDomiButton}
              </Button>
            )}
            {/* <Button
              variant="outlined"
              color="primary"
              startIcon={showDetails ? <ExpandLess /> : <ExpandMore />}
              onClick={() => setShowDetails(!showDetails)}
              disabled={!tokenAccounts || tokenAccounts.length <= 0}
            >
              {showDetails ? 'Hide' : 'Show'} Mints
            </Button> */}
          </div>

          {/* <Collapse in={showDetails} timeout="auto" unmountOnExit>
            {tokenAccounts?.map((account) => (
              <BalanceListItem
                key={account.pubkey}
                publicKey={account.pubkey}
                programId={programId}
              />
            ))}
          </Collapse> */}
        </div>

        {match(programId)
          .with(BTCI_PROGRAM_ID, () => (
            <>
              <BtcDepositDialog
                open={depositDialogOpen}
                onClose={() => setDepositDialogOpen(false)}
              />
              <BtcSendDialog
                open={sendDialogOpen}
                onClose={() => setSendDialogOpen(false)}
              />
            </>
          ))
          .with(USDT_PROGRAM_ID, () => (
            <>
              <UsdtDepositDialog
                open={depositDialogOpen}
                onClose={() => setDepositDialogOpen(false)}
              />
              <UsdtSendDialog
                open={sendDialogOpen}
                onClose={() => setSendDialogOpen(false)}
              />
              <UsdtExchangeDialog
                open={exchangeDialogOpen}
                onClose={() => setExchangeDialogOpen(false)}
              />
            </>
          ))
          .exhaustive()}
      </>
    );
  },
);

const useStyles = makeStyles((theme) => ({
  address: {
    textOverflow: 'ellipsis',
    overflowX: 'hidden',
  },
  itemDetails: {
    // paddingLeft: theme.spacing(2),
    // paddingRight: theme.spacing(2),
    backgroundColor: theme.palette.background.paper,
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-evenly',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  viewDetails: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
}));
