import { Button, DialogActions, Typography } from '@material-ui/core';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import { useMutation } from '@tanstack/react-query';
import React, { useState } from 'react';
import strings from '../../localization';
import TelegramBotService, { TelegramBotCheckResponse } from '../../services/telegramBotService';
import { chromeLocalStorage } from '../../utils/chrome-storage-polyfill';
import { DOMICHAIN_TWITTER_URL } from '../../utils/env-variables';
import { useLocalStorage } from '../../utils/hookHelpers';
import CopyableDisplay from '../CopyableDisplay';
import DialogForm from '../DialogForm';

const useStyles = makeStyles((theme) => ({
  explorerLink: {
    marginBottom: theme.spacing(4),
  },
}));

export interface TelegramSubscribeDialogProps {
  open: boolean;
  onAccepted?: () => void;
  onClose: () => void;
}

export interface TelegramPrefetchProps {
  created: number;
  count: number;
}

export default function TelegramSubscribeDialog({ open, onClose }: TelegramSubscribeDialogProps) {
  const [_, setAccepted] = useState(false);
  const [twitterSubscribed, setTwitterSubscribed] = useState(false);

  const telegramBotService = new TelegramBotService();

  const hint = telegramBotService.generateHint();
  const telegramUrl = telegramBotService.createSubscriberBotUrl(hint);
  const pollingIntervalMs = 2500;
  const maxRetries = 100;

  const twitterUrl = DOMICHAIN_TWITTER_URL;
  const onTwitterClick = () => {
    setTwitterSubscribed(true);
  }

  useLocalStorage('tmpPrefetch', (props: TelegramPrefetchProps | undefined) => {
    const created = props ? props.created > 0 : false;
    setAccepted(created);
  });

  const { mutate, isLoading } = useMutation({
    mutationFn: async () => {
      console.log('Requesting TG bot with hint:', hint);
      const response = await telegramBotService.check(hint);

      if (!response.accepted) {
        throw new Error('Prefetch failed');
      }
      return response;
    },
    onSuccess: (resp: TelegramBotCheckResponse) => {
      console.log('TG bot onSuccess, accepted');
      const props: TelegramPrefetchProps = {
        created: Date.now(),
        count: resp.userId,
      };

      chromeLocalStorage.set({
        tmpPrefetch: props,
      });
      onClose();
    },
    onError: (error: any) => {
      console.error('TG bot onError', error);
    },
    retry: maxRetries,
    retryDelay: pollingIntervalMs,
  });

  return (
    <>
      <DialogForm open={open} onClose={onClose} onSubmit={onClose}>
        <DialogTitle>
          {strings.telegramSubscriptionTitle}
        </DialogTitle>
        <DialogContent>
          {
            !twitterSubscribed ?
              <SubscribeTwitter twitterUrl={twitterUrl} onClick={() => onTwitterClick()} /> :
              <SubscribeTelegram telegramUrl={telegramUrl} />
          }
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>{strings.close}</Button>
          <Button color='primary' disabled={isLoading || !twitterSubscribed} onClick={() => mutate()}>
            {isLoading ? strings.loading : strings.airdrop}
          </Button>
        </DialogActions>
      </DialogForm>
    </>
  );
}

export function SubscribeTwitter({ twitterUrl, onClick }) {
  const classes = useStyles();

  return <>
    <Typography variant='body2'>
      {strings.subscribeTwitterInstructions}
    </Typography>

    <Typography variant='body1' className={classes.explorerLink}>
      <Link
        href={twitterUrl}
        target='_blank'
        rel='noopener'
        onClick={() => onClick && onClick()}
      >
        {strings.twitterSubscriptionLink}
      </Link>
    </Typography>
  </>
}

export function SubscribeTelegram({ telegramUrl }) {
  const classes = useStyles();

  return <>
    <Typography variant='body2'>
      <ol type="1">
        <li>{strings.telegramInstructions1}
          <ul>
            <li>{strings.telegramInstructions2}</li>
            <li>{strings.telegramInstructions3}</li>
          </ul>
        </li>
        <li>{strings.telegramInstructions4}</li>
        <li>{strings.telegramInstructions5}</li>
        <li>{strings.telegramInstructions6}</li>
        <li>{strings.telegramInstructions7}</li>
      </ol>
    </Typography>

    <Typography variant='body1' className={classes.explorerLink}>
      <Link
        href={telegramUrl}
        target='_blank'
        rel='noopener'
      >
        {strings.telegramSubscriptionLink}
      </Link>
    </Typography>
    <CopyableDisplay
      value={telegramUrl}
      label={strings.telegramSubscriptionLink2}
      autoFocus={false}
      qrCode
    />
  </>
}
