import { Suspense, useEffect, useMemo, useState } from 'react';
import {
  makeStyles,
  List,
  ListItem,
  BottomNavigation,
  BottomNavigationAction,
  Box,
  Paper,
} from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import {
  ThemeProvider,
  unstable_createMuiStrictModeTheme as createMuiTheme,
} from '@material-ui/core/styles';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import DialogForm from './components/DialogForm';
import NavigationFrame from './components/NavigationFrame';
import { ConnectionProvider } from './utils/connection';
import WalletPage from './pages/WalletPage';
import { useWallet, WalletProvider } from './utils/wallet';
import { ConnectedWalletsProvider } from './utils/connected-wallets';
import { TokenRegistryProvider } from './utils/tokens/names';
import LoadingIndicator from './components/LoadingIndicator';
import { SnackbarProvider } from 'notistack';
import PopupPage from './pages/PopupPage';
import LoginPage from './pages/LoginPage';
import ConnectionsPage from './pages/ConnectionsPage';
import { isExtension } from './utils/utils';
import { PageProvider, usePage } from './utils/page';
import strings from './localization';
import moment from 'moment/min/moment-with-locales';
import 'moment-timezone';
import { persistOptions, queryClient } from './utils/query-client';
import { AccountBalanceWalletTwoTone, PublicTwoTone } from '@material-ui/icons';
import { orange } from '@material-ui/core/colors';
import { PersistQueryClientProvider } from '@tanstack/react-query-persist-client';

const theme = createMuiTheme({
  palette: {
    type: 'dark',
    primary: orange,
  },
  // TODO consolidate popup dimensions
  ext: '500',
});

function useForceUpdate() {
  const [_, setValue] = useState(0); // integer state
  return () => setValue((value) => value + 1); // update state to force render
}

export default function App() {
  // TODO: add toggle for dark mode
  const forceUpdate = useForceUpdate();

  useEffect(() => {
    if (window.cordova) {
      const timeout = setTimeout(() => navigator.splashscreen?.hide(), 100);
      return () => clearTimeout(timeout);
    }
  }, []);

  // Disallow rendering inside an iframe to prevent clickjacking.
  if (window.self !== window.top) {
    return null;
  }

  const initLanguage = () => {
    let lang = window.localStorage.getItem('lang');
    console.log('Found saved language', lang);

    if (lang) {
      strings.setLanguage(lang);
      moment.locale(lang === 'zh' ? 'zh-cn' : lang);
    } else {
      let lang = strings.getLanguage();
      console.log('Found default language', lang);
      window.localStorage.setItem('lang', lang);
      moment.locale(lang === 'zh' ? 'zh-cn' : lang);
    }
  };

  const changeLanguage = (newLang) => {
    if (!newLang) return;
    strings.setLanguage(newLang);
    window.localStorage.setItem('lang', newLang);
    moment.locale(newLang === 'zh' ? 'zh-cn' : newLang);
    forceUpdate();
  };

  initLanguage();

  let appElement = (
    <PageProvider>
      <NavigationFrame onLanguageChange={changeLanguage}>
        <Suspense fallback={<LoadingIndicator />}>
          <PageContents />
        </Suspense>
      </NavigationFrame>
    </PageProvider>
  );

  if (isExtension || window.cordova) {
    appElement = (
      <ConnectedWalletsProvider>{appElement}</ConnectedWalletsProvider>
    );
  }

  return (
    <Suspense fallback={<LoadingIndicator />}>
      <PersistQueryClientProvider
        client={queryClient}
        persistOptions={persistOptions}
      >
        <ThemeProvider theme={theme}>
          <CssBaseline />

          <ConnectionProvider>
            <TokenRegistryProvider>
              <SnackbarProvider maxSnack={5} autoHideDuration={8000}>
                <WalletProvider>{appElement}</WalletProvider>
              </SnackbarProvider>
            </TokenRegistryProvider>
          </ConnectionProvider>
        </ThemeProvider>
      </PersistQueryClientProvider>
    </Suspense>
  );
}

function PageContents() {
  const wallet = useWallet();
  const classes = useStyles();
  const [page, setPage] = usePage();
  const [showWalletSuggestion, setShowWalletSuggestion] = useState(true);
  const suggestionKey = 'private-irgnore-wallet-suggestion';
  const ignoreSuggestion = window.localStorage.getItem(suggestionKey);
  const type = useMemo(() => {
    let params = new URLSearchParams(window.location.hash.slice(1));
    return params.get('type');
  }, []);
  if (!wallet) {
    return (
      <>
        {!ignoreSuggestion && false && (
          <WalletSuggestionDialog
            open={showWalletSuggestion}
            onClose={() => setShowWalletSuggestion(false)}
            onIgnore={() => {
              window.localStorage.setItem(suggestionKey, true);
              setShowWalletSuggestion(false);
            }}
          />
        )}
        <LoginPage />
      </>
    );
  }
  if (type === 'popup') {
    return <PopupPage />;
  }

  console.log(`rendering ${page}`);

  let pageComponent = null;

  if (page === 'wallet') {
    pageComponent = <WalletPage />;
  } else if (page === 'connections') {
    pageComponent = <ConnectionsPage />;
  }

  return (
    <>
      <Box paddingBottom={8}>{pageComponent}</Box>
      <Paper className={classes.tabBar} elevation={3}>
        <BottomNavigation
          value={page}
          onChange={(_, newValue) => setPage(newValue)}
          showLabels
        >
          <BottomNavigationAction
            value="wallet"
            label="Wallet"
            icon={<AccountBalanceWalletTwoTone />}
          />
          {(isExtension ||
            (window.cordova && window.cordova.platformId !== 'ios')) && (
            <BottomNavigationAction
              value="connections"
              label="Connections"
              icon={<PublicTwoTone />}
            />
          )}
          {/* <BottomNavigationAction
            value="staking"
            label="Staking"
            icon={<StarTwoTone />}
          /> */}
        </BottomNavigation>
      </Paper>
    </>
  );
}

function WalletSuggestionDialog({ open, onClose, onIgnore }) {
  const classes = useStyles();
  return (
    <DialogForm open={open} onClose={onClose} fullWidth>
      <DialogTitle>Looking for a Wallet?</DialogTitle>
      <DialogContent>
        <Typography>
          For the best Domichain experience, it is recommended to use{' '}
          <b>Backpack</b>
        </Typography>
        <List disablePadding style={{ marginTop: '16px' }}>
          <ListItem button disablePadding style={{ padding: 0 }}>
            <div
              className={classes.walletButton}
              style={{ display: 'flex' }}
              onClick={() => {
                window.location = 'https://backpack.app/download';
              }}
            >
              <div>
                <img
                  alt=""
                  style={{ height: '39px' }}
                  src="https://github.com/coral-xyz/backpack/raw/master/assets/backpack.png"
                />
              </div>
              <div>
                <Typography
                  style={{
                    marginLeft: '16px',
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    height: '39px',
                    fontWeight: 'bold',
                  }}
                >
                  Backpack
                </Typography>
              </div>
            </div>
          </ListItem>
        </List>
      </DialogContent>
      <DialogActions>
        <Button type="submit" color="primary" onClick={onIgnore}>
          Ignore Future Dialog
        </Button>
        <Button type="submit" color="primary" onClick={onClose}>
          Ok
        </Button>
      </DialogActions>
    </DialogForm>
  );
}

const useStyles = makeStyles(() => ({
  tabBar: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
  },
  walletButton: {
    width: '100%',
    padding: '16px',
    '&:hover': {
      cursor: 'pointer',
    },
  },
}));
