import { getDeviceUuid, getSignature } from '../components/BalancesList/helpers';
import strings from '../localization';
import { AIRDROP_BACKEND_URL } from '../utils/env-variables';

export class RateLimitError extends Error {
}

export default class AirdropService {
  async register(wallet: any): Promise<boolean> {
    const response = await fetch(
      `${AIRDROP_BACKEND_URL}/v1/register-device`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
        body: JSON.stringify({
          domichainAddress: wallet.publicKey.toBase58(),
          deviceUuid: getDeviceUuid(),
          signature: await getSignature(wallet),
        }),
      },
    );

    if (!response.ok) {
      if (response.status === 429) {
        const body = await response.json();
        if ('error' in body) {
          throw new RateLimitError(body.error.message);
        }
      }
      throw new Error(strings.errorOccurredTryAgainLater);
    }

    return true;
  }
}
