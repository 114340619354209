const korean = {
  applicationName: '도미체인 지갑',
  unlockWallet: '지갑 잠금 해제',
  choosePassword: '비밀번호를 선택하세요(선택 사항).',
  pickPassword: '선택적으로 지갑을 보호하기 위해 암호를 선택하십시오.',
  ifForgetPassword:
    '비밀번호를 잊은 경우 시드 단어를 사용하여 지갑을 복원해야 합니다.',
  restoreExistingWallet: '기존 지갑 복원',
  createNewWallet: '새 지갑 만들기',
  createToHold: 'Domichain 및 DPL 토큰을 보관할 새 지갑 만들기',
  seedSafePlace: '다음 열두 단어를 적어 안전한 곳에 보관하세요:',
  privateKeysStored:
    '개인 키는 현재 컴퓨터나 장치에만 저장됩니다. 브라우저의 저장 공간이 비워지거나 장치가 손상되거나 분실된 경우 지갑을 복원하려면 이 단어가 필요합니다.',
  iHaveSaved: '나는 이 말을 안전한 곳에 저장해 두었습니다.',
  downloadMnemonic: '백업 니모닉 파일 다운로드(필수)',
  pleaseReenter: '시드 구문을 다시 입력하여 저장했는지 확인하십시오.',
  pleaseType: '확인을 위해 시드 문구를 입력하세요.',
  newPassword: '새 비밀번호',
  confirmPassword: '비밀번호 확인',
  back: '뒤쪽에',
  createWallet: '지갑 생성',
  unlockingWallet: '지갑을 여는 중...',
  walletUnlocked: '지갑이 잠금 해제됨',
  password: '비밀번호',
  keepWalletUnlocked: '지갑을 잠금 해제 상태로 유지',
  restoreExisting: '기존 지갑 복원',
  restoreUsing:
    '12개 또는 24개의 시드 단어를 사용하여 지갑을 복원하십시오. 이렇게 하면 이 장치에 있는 기존 지갑이 삭제됩니다.',
  noHardwareWallet:
    '여기에 하드웨어 지갑 시드 문구를 입력하지 마십시오. 하드웨어 지갑은 웹 지갑 생성 후 선택적으로 연결할 수 있습니다.',
  mnemonicFailed:
    '니모닉 유효성 검사에 실패했습니다. 유효한 BIP 39 시드 문구를 입력하십시오.',
  seedWords: '시드 단어',
  newPasswordOptional: '새 비밀번호(선택 사항)',
  cancel: '취소',
  next: '다음',
  addAccount: '계정을 추가',
  name: '이름',
  importPrivateKey: '개인 키 가져오기',
  pastePrivateKey: '개인 키 가져오기',
  add: '추가하다',
  close: '닫다',
  addCustomNetwork: '사용자 지정 네트워크 추가',
  url: 'URL',
  done: '완료',
  continue: '계속하다',
  export: '내보내다',
  delete: '삭제',
  send: '보내다',
  receive: '받다',
  unlock: '터놓다',
  confirmAddress: '지갑 주소 확인',
  checkLedger:
    "원장을 확인하고 표시된 주소가 선택한 주소인지 확인합니다. 그런 다음 '완료'를 클릭합니다.",
  addHardwareWallet: '하드웨어 지갑 추가',
  connectLedger:
    "원장을 연결하고 Domichain 애플리케이션을 엽니다. 준비가 되면 '계속'을 클릭합니다.",
  loadingFromHardwareWallet: '하드웨어 지갑에서 계정 로드',
  copied: '복사됨',
  copyToClipboard: '클립 보드에 복사',
  editAccountName: '계정 이름 편집',
  auxiliaruAccount: '이것은 보조 토큰 계정입니다.',
  viewOnDomiexplorer: 'Domiexplorer에서 보기',
  viewDetails: '세부 정보보기',
  tokenName: '토큰 이름',
  tokenSymbol: '토큰 심볼',
  mainAccount: '메인 계정',
  account: '계정',
  refresh: '새로 고치다',
  wontRecover:
    '시드 문구와 계정 개인 키가 없으면 현재 계정을 복구할 수 없습니다. 이 작업은 브라우저에서 모든 현재 계정을 삭제합니다.',
  perventLoss:
    "자금 손실을 방지하려면 모든 현재 계정에 대한 시드 문구와 개인 키가 있는지 확인하십시오. 사용자 메뉴에서 '니모닉 내보내기'를 선택하면 볼 수 있습니다.",
  enterSeedPhase: '확인을 위해 시드 문구를 입력하세요.',
  deleteAndLogout: '니모닉 삭제 및 로그아웃',
  editAccount: '계정 수정',
  addressReceive: '이 주소를 사용하여 수신할 수 있습니다.',
  depositAddress: '입금 주소',
  enterDomichainAddress: '도미체인 주소 입력',
  enterDPLAddress: 'DPL 토큰 또는 도미체인 주소 입력',
  amount: '양',
  max: '최대',
  recipientAddress: '받는 사람 주소',
  use1: '이용해주세요',
  use2: '웜홀 차원문 다리',
  use3: '자산을 연결합니다.',
  save: '구하다',
  exportAccount: '계정 내보내기',
  privateKey: '개인 키',
  reveal: '드러내다',
  mnemonic: '니모닉',
  connect: '연결하다',
  exportMnemonic: '니모닉 내보내기',
  importHardwareWallet: '하드웨어 지갑 가져오기',
  selectAccount: '계정 선택',
  editCustomEndpoint: '사용자 지정 끝점 편집',
  addCustomEndpoint: '사용자 지정 끝점 추가',
  hardwareWallet: '하드웨어 지갑',
  selectNetwork: '네트워크 선택',
  selectLanguage: '언어 선택',
  connections: '사이',
  manageConnections: '연결 관리',
  wallet: '지갑',
  walletBalance: '월렛 잔액',
  expandView: '전개도',
  appName: 'DWallet',
  disconnect: '연결 끊기',
  connectedDapps: '연결된 Dapp',
  sendRequest: '요청 보내기',
  waitingForConfirmation: '확인을 기다리는 중',
  withdrawFunds: '자금 인출',
  deposit: '보증금',
  metamaskConnected: '메타마스크 연결됨',
  transactionPending: '거래 대기 중',
  language: '언어',
  confirmMnenonic: '니모닉 확인',
  creatingWallet: '지갑 생성 중...',
  walletCreated: '지갑 생성됨',
  time: '시간',
  txHash: '트랜잭션 해시',
  type: '유형',
  transfer: '전송',
  other: '기타',
  approve: '승인',
  deny: '거부',
  connectWalletDialogQuestion:
    '이 Dapp이 도메체인 계정에 액세스하도록 허용하시겠습니까?',
  connectWalletDialogNotice:
    '모든 거래는 자동으로 승인되며, 신뢰할 수 있는 사이트와만 연결됩니다. "연결된 앱" 섹션에서 언제든지 변경할 수 있습니다.',
  allowTransactionDialogQuestion:
    '이 디앱이 트랜잭션을 전송하도록 허용하시겠습니까?',
  airdrop: '에어드롭',
  addressHasNoFunds: '이 주소에는 자금이 없습니다. 정확한 주소인가요?',
  mintNewToken: '민트 새 토큰',
  mintNewTokenDescription:
    '새로운 가치 없는 토큰을 생성하고 받습니다. 이 작업을 수행하려면 DOMI가 거래 수수료를 부담해야 합니다.',
  tokenDecimals: '토큰 소수점',
  stake: '스테이크',
  yourStake: '내 스테이크',
  stakeAccount: '스테이크 계정',
  status: '상태',
  balance: '잔액',
  activeStake: '활성 스테이크',
  inactiveStake: '비활성 스테이크',
  reward: '보상',
  unstake: '언스테이크하기',
  withdrawStake: '스테이크 인출하기',
  startStaking: '스테이킹 시작',
  stakingChoosePlanDescription:
    "'아래의 계획 중 하나를 선택하여 DOMI를 스테이킹하고 보상을 받으세요.",
  stakingDepositDescription:
    '지정된 토큰 금액으로 BNB Smart Chain에 USDT를 입금하세요.\n\n입금 후 자동으로 스테이킹 대상자가 됩니다.',
  stakingDelegateDescription: '검증자와 함께할 DOMI 양을 선택하세요.',
  stakingActivatingDescription:
    '스터ㅏㅋ운트 활성화 시 다음 몇 일 내에 DOMI가 보상을 받기 시작합니다.',
  stakingActiveDescription:
    '현재 검증자와 함께 DOMI를 지분하고 있습니다. 이 자금에 접근하려면 언스태일 해야 합니다.',
  stakingPlan: '계략',
  stakingAnnualFee: '연간 수수료',
  stakingProduct: '제도물',
  stakingAnnualRoi: '연간 ROI(수익률)',
  stakingLimit: '스테이킹 한도',
  stakingUrl: '스테이킹 URL',
  unlimited: '무한',
  validator: '검증자',
  activating: '활성화',
  active: '활성',
  deactivating: '비활성화',
  inactive: '비활성',
  showDepositAddress: '입금 주소 표시',
  confirmUnstakeTitle: '스테이킹 해제 확인',
  confirmUnstakeDescription:
    '스테이킹을 취소하시겠습니까? 보상을 더 이상 받을 수 없지만 자금을 인출할 수 있습니다.',
  installPwaTitle: 'DWallet 설치',
  installPwaDescription:
    '기기에 앱을 설치하면 언제든지 쉽게 액세스할 수 있습니다.',
  installPwaStepOne: '1. "공유" 버튼을 탭합니다.',
  installPwaStepTwo: '2. "홈 화면에 추가"를 선택합니다.',
  installPwaStepThree:
    '3. 홈 화면으로 이동하여 DWallet을 찾은 다음 DWallet을 클릭합니다.',
  redPacketTitle: '새해 복 많이 받으세요!',
  redPacketAvailable: '무료 레드패킷 선물을 받으려면 여기를 누르세요',
  redPacketUnavailable: '%s에 다시 돌아와서 다른 선물을 받으세요',
  redPacketSnackbar: '레드패킷 선물로부터 %s DOMI를 획득하였습니다!',
  errorOccurredTryAgainLater: '오류가 발생했습니다. 나중에 다시 시도하세요.',
  settings: '설정',
  free: '무료',
  welcomeAirdropTitle: 'DWallet에 오신 것을 환영합니다!',
  welcomeAirdropAvailable:
    '여기를 누르면 무료 도미 1개를 선물로 받을 수 있습니다.',
  welcomeAirdropUnavailable: '다른 선물을 받으려면 %s 로 돌아와 주세요.',
  welcomeAirdropSnackbar: '환영 선물로 %s DOMI를 받았습니다!',
  usdtDepositNotice:
    '이 주소는 %s에서만 USDT를 받을 수 있습니다. 다른 네트워크의 토큰을 보내지 마세요! USDT를 받은 후 이를 사용하여 DOMI를 구매하고 SESA 에어드랍을 받을 수 있습니다.',
  usdtContractAddress: 'USDT 계약 주소:',
  exchange: '거래소',
  loading: '로드 중...',
  expectedFee: '예상 수수료', // Expected fee N BTC
  youWillReceive: '당신은 받게됩니다', // You will receive N BTC
  selectTheFee: '수수료를 선택하세요',
  fastest: '가장 빠른', // Fastest N sat/vB
  normal: '정상', // Normal N sat/vB
  economy: '경제', // Economy N sat/vB
  sendingTransaction: '거래를 보내는 중...',
  transactionConfirmed: '거래가 확인되었습니다',
  failedToSignAndSendAMultisigTransaction: '다중 서명 트랜잭션에 서명하고 전송하지 못했습니다.',
  noAccountsFound: '계정을 찾을 수 없습니다',
  requestFailed: '요청 실패',
  noFeeRates: '수수료 없음',
  insufficientFunds: '자금 부족',
  tenMinutes: '~10분',
  thirtyMinutes: '~30분',
  oneHour: '~1시간',
  buyDomiItemTitle: 'DOMI 구매',
  buyDomiItemSubtitle: 'USDT를 입금하여 DOMI를 구매하세요',
  buyDomiButton: 'DOMI 구매',
  buyDomiDialogTitle: 'DOMI 구매',
  buyDomiMinimumUsdtAmount: '최소 USDT 금액: {0} USDT',
  buyDomiReceiveNotice: '교환 후 2개의 USDT마다 2,000개의 SESA 토큰과 1개의 DOMI를 즉시 받게 됩니다.',
  buyButton: '구입하다',
  telegramSubscriptionTitle: '우리의 소셜 네트워크를 구독하세요',
  telegramSubscriptionLink: 'Telegram에서 열려면 클릭하세요',
  telegramSubscriptionLink2: '텔레그램 커뮤니티 링크:',
  telegramInstructions1: 'Telegram이 없는 경우:',
  telegramInstructions2: '기기에 Telegram을 설치하세요',
  telegramInstructions3: '또는 Telegram이 있는 다른 기기로 QR 코드를 스캔하세요',
  telegramInstructions4: '링크를 클릭하거나 브라우저에서 여세요',
  telegramInstructions5: '"시작" 버튼을 눌러 Telegram 봇과 채팅을 시작하세요',
  telegramInstructions6: '봇의 지시에 따라 Telegram 커뮤니티에 가입하세요',
  telegramInstructions7: 'DWallet으로 돌아가서 "계속" 버튼을 누르세요',
  subscribeTwitterInstructions: '트위터에서 우리 커뮤니티를 팔로우하세요:',
  twitterSubscriptionLink: '트위터에서 열려면 여기를 클릭하고 "팔로우" 버튼을 클릭하세요.',
};

export default korean;
