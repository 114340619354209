import en_flag from '../assets/en_flag_sm.png';
import zh_flag from '../assets/zh_flag_sm.png';
import ko_flag from '../assets/ko_flag_sm.png';

export default function LanguageIcon({ lang }) {
  let images = {};
  images['en'] = en_flag;
  images['zh'] = zh_flag;
  images['ko'] = ko_flag;

  return <img alt={lang} src={images[lang]} style={{ width: 22.5 }}></img>;
}
