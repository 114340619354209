import {
  DialogContentText,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {
  LAMPORTS_PER_SOL,
  PublicKey,
  StakeActivationData,
  StakeProgram,
} from '@solana/web3.js';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { P, match } from 'ts-pattern';
import strings from '../../../localization';
import { useConnection } from '../../../utils/connection';
import { useSendTransaction } from '../../../utils/notifications';
import { signAndSendTransaction } from '../../../utils/tokens';
import { abbreviateAddress, formatLamports } from '../../../utils/utils';
import { useBalanceInfo, useWallet } from '../../../utils/wallet';
import { useState } from 'react';
import { Box } from '@mui/material';
import StakingSettingsDialog from '../StakingSettingsDialog';
import DialogForm from '../../DialogForm';

const rewardsFormat = new Intl.NumberFormat(undefined, {
  minimumFractionDigits: 8,
  maximumFractionDigits: 8,
  useGrouping: true,
});

export default function ActiveStakeInfo({
  stakeAccount,
  stakeAccountInfo,
  stakingBackendUrl,
  onClose,
}: {
  stakeAccount: PublicKey;
  stakeAccountInfo: StakeActivationData;
  stakingBackendUrl: string;
  onClose: () => void;
}) {
  const wallet = useWallet();
  const connection = useConnection();
  const queryClient = useQueryClient();
  const stakeAccountBalance = useBalanceInfo(stakeAccount);
  const [sendTransaction, sending] = useSendTransaction();

  const [showAlert, setShowAlert] = useState(false);

  const { data: activeStakeInfo, refetch: refetchActiveStakeInfo } = useQuery({
    queryKey: [
      'activeStakeInfo',
      wallet.publicKey?.toBase58(),
      stakeAccount.toBase58(),
      stakingBackendUrl,
    ],
    queryFn: async () => {
      const response = await fetch(
        `${stakingBackendUrl}/v1/stakes/${stakeAccount.toBase58()}`,
      );
      const data = await response.json();
      return data.data;
    },
    retry: false,
  });

  const { data: inflationReward } = useQuery({
    queryKey: ['getInflationReward', stakeAccount.toBase58()],
    queryFn: async () => {
      const result = await connection.getInflationReward([stakeAccount]);
      return result?.[0];
    },
    enabled: Boolean(stakeAccountInfo),
    refetchInterval: 60 * 1000,
  });

  const deactivateStake = async () => {
    const transaction = StakeProgram.deactivate({
      stakePubkey: stakeAccount,
      authorizedPubkey: wallet.publicKey,
    });

    const { blockhash, lastValidBlockHeight } =
      await connection.getLatestBlockhash();

    transaction.feePayer = wallet.publicKey;
    transaction.recentBlockhash = blockhash;
    transaction.lastValidBlockHeight = lastValidBlockHeight;

    return await signAndSendTransaction(connection, transaction, wallet, []);
  };

  const withdrawStake = async () => {
    const transaction = StakeProgram.withdraw({
      stakePubkey: stakeAccount,
      authorizedPubkey: wallet.publicKey,
      toPubkey: wallet.publicKey,
      lamports: await connection.getBalance(stakeAccount),
    });

    const { blockhash, lastValidBlockHeight } =
      await connection.getLatestBlockhash();

    transaction.feePayer = wallet.publicKey;
    transaction.recentBlockhash = blockhash;
    transaction.lastValidBlockHeight = lastValidBlockHeight;

    return await signAndSendTransaction(connection, transaction, wallet, []);
  };

  const removeActiveStakeFromDB = async () => {
    try {
      const message = Buffer.from('domichain-staking');
      const signature = await wallet.createSignature(message);

      const url = new URL(
        `${stakingBackendUrl}/v1/stakes/${stakeAccount.toBase58()}`,
      );

      url.searchParams.append('ownerAddress', wallet.publicKey.toBase58());
      url.searchParams.append('signature', signature);

      await fetch(url, { method: 'DELETE' });
    } catch (error) {
      console.warn(`Failed to delete active stake info: `, error);
    } finally {
      refetchActiveStakeInfo();
    }
  };

  const refreshStakeAccountInfo = () => {
    queryClient.refetchQueries({
      queryKey: ['stakeAccountInfo'],
    });
  };

  const handleOnPressUnstake = () => {
    sendTransaction(deactivateStake(), {
      onSuccess: refreshStakeAccountInfo,
    });
  };

  const handleOnPressWithdraw = () => {
    sendTransaction(withdrawStake(), {
      onSuccess: () => {
        removeActiveStakeFromDB();
        refreshStakeAccountInfo();
        onClose();
      },
    });
  };

  const initialStake = parseInt(
    activeStakeInfo?.stakeAmount ??
      localStorage.getItem(`initialStake_${stakeAccount.toBase58()}`),
  );

  const reward = !!initialStake
    ? stakeAccountInfo.active + stakeAccountInfo.inactive - initialStake
    : inflationReward?.amount ?? 0;

  return (
    <>
      <DialogTitle>
        <Box display="flex" alignItems="center">
          <Box flexGrow={1}>{strings.yourStake}</Box>
          <Box>
            <StakingSettingsDialog />
          </Box>
        </Box>
      </DialogTitle>

      <DialogContent>
        <DialogContentText>
          {match(stakeAccountInfo)
            .with(
              { state: 'activating' },
              () => strings.stakingActivatingDescription,
            )
            .otherwise(() => strings.stakingActiveDescription)}
        </DialogContentText>

        <TableContainer component={Paper} variant="outlined">
          <Table>
            <TableBody>
              {[
                [strings.stakeAccount, abbreviateAddress(stakeAccount)],
                [
                  strings.status,
                  match(stakeAccountInfo.state)
                    .with('activating', () => strings.activating)
                    .with('active', () => strings.active)
                    .with('deactivating', () => strings.deactivating)
                    .with('inactive', () => strings.inactive)
                    .exhaustive(),
                ],
                [
                  strings.balance,
                  `${formatLamports(
                    inflationReward?.postBalance ?? stakeAccountBalance?.amount,
                  )} DOMI`,
                ],
                [
                  strings.activeStake,
                  `${formatLamports(stakeAccountInfo?.active)} DOMI`,
                ],
                [
                  strings.inactiveStake,
                  `${formatLamports(stakeAccountInfo?.inactive)} DOMI`,
                ],
                [
                  strings.reward,
                  `${rewardsFormat.format(reward / LAMPORTS_PER_SOL)} DOMI`,
                ],
              ].map(([name, value]) => (
                <TableRow>
                  <TableCell component="th" scope="row">
                    {name}
                  </TableCell>
                  <TableCell align="right">
                    <b>{value}</b>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose}>{strings.close}</Button>

        {match(stakeAccountInfo)
          .with({ state: P.union('activating', 'active') }, () => (
            <Button
              color="primary"
              onClick={() => setShowAlert(true)}
              disabled={sending}
            >
              {strings.unstake}
            </Button>
          ))
          .with(
            { state: P.union('deactivating', 'inactive').select() },
            (state) => (
              <Button
                color="primary"
                onClick={handleOnPressWithdraw}
                disabled={state === 'deactivating' || sending}
              >
                {strings.withdrawStake}
              </Button>
            ),
          )
          .otherwise(() => null)}
      </DialogActions>

      <DialogForm
        open={showAlert}
        onClose={() => setShowAlert(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {strings.confirmUnstakeTitle}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {strings.confirmUnstakeDescription}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowAlert(false)}>{strings.cancel}</Button>
          <Button color="primary"
            onClick={() => {
              handleOnPressUnstake();
              setShowAlert(false);
            }}
          >
            {strings.unstake}
          </Button>
        </DialogActions>
      </DialogForm>
    </>
  );
}

// async function getStakeAccount(wallet: Wallet, voteAccount: VoteAccountInfo) {
//   return await PublicKey.createWithSeed(
//     wallet.publicKey,
//     voteAccount.votePubkey,
//     StakeProgram.programId,
//   );
// }
