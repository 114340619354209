import React from 'react';
import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import strings from '../../localization';

export interface BuyUsdtListItemProps {
  iconSize?: 'small' | 'medium';
  onClick?: () => void;
}

export default function BuyDomiListItem({ iconSize, onClick }: BuyUsdtListItemProps) {
  return (
    <ListItem
      button
      onClick={() => onClick && onClick()}
    >
      <ListItemIcon>
        <AccountBalanceIcon size={iconSize} />
      </ListItemIcon>

      <ListItemText
        primary={strings.buyDomiItemTitle}
        primaryTypographyProps={{ style: { fontWeight: '600' } }}
        secondary={strings.buyDomiItemSubtitle}
      />
    </ListItem>
  );
}
