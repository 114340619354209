import React from 'react';
import { useConnection } from '../utils/connection';
import { useWallet } from '../utils/wallet';

import { LAMPORTS_PER_SOL, PublicKey, SystemProgram } from '@solana/web3.js';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import moment from 'moment/min/moment-with-locales';
import strings from '../localization';
import { Box, Button, Link, styled } from '@material-ui/core';
import { useInfiniteQuery } from '@tanstack/react-query';
import { EXPLORER_URL } from '../utils/env-variables';
import { BTCI_PROGRAM_ID, USDT_PROGRAM_ID } from '../utils/tokens/instructions';
import { findAssociatedTokenAddress } from '../utils/tokens';

const SIGNATURES_PER_PAGE = 10;

const PROGRAM_ID_TO_TICKER_MAPPING = {
  [SystemProgram.programId]: 'DOMI',
  [BTCI_PROGRAM_ID]: 'BTC',
  [USDT_PROGRAM_ID]: 'USDT',
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    fontSize: 14,
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.secondary,
    border: `1px solid ${theme.palette.divider}`,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary,
    border: `1px solid ${theme.palette.divider}`,
  },
}));

export function TransactionHistory() {
  const wallet = useWallet();
  const connection = useConnection();

  const { data, fetchNextPage, hasNextPage, isFetching, isFetchingNextPage } =
    useInfiniteQuery({
      queryKey: [
        'transactions',
        wallet.publicKey.toBase58(),
        connection.rpcEndpoint,
      ],
      queryFn: ({ pageParam }) =>
        loadTransactions(connection, wallet.publicKey, pageParam),
      getNextPageParam: (lastPage) =>
        lastPage.length >= SIGNATURES_PER_PAGE
          ? lastPage[lastPage.length - 1].signature
          : undefined,
    });

  if ((isFetching && !isFetchingNextPage) || !data || data.length <= 0) {
    return null;
  }

  return (
    <>
      <TableContainer component={Paper} style={{ marginTop: 36 }}>
        <Table>
          <TableHead>
            <TableRow>
              {[strings.time, strings.txHash, strings.type, strings.amount].map(
                (it) => (
                  <StyledTableCell align="center">{it}</StyledTableCell>
                ),
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.pages.map((group, i) => (
              <React.Fragment key={i}>
                {group.map((row) => (
                  <TableRow
                    key={row.signature}
                    sx={{
                      '&:last-child td, &:last-child th, &:first-child td, &:first-child th':
                        { border: 0 },
                    }}
                  >
                    <StyledTableCell
                      align="center"
                      title={moment(row.blockTime * 1000).format('lll')}
                    >
                      {moment(row.blockTime * 1000).fromNow()}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Link href={`${EXPLORER_URL}/tx/${row.signature}`}>
                        {row.signature.slice(0, 8)}...
                      </Link>
                    </StyledTableCell>
                    <StyledTableCell
                      align="center"
                      style={{ whiteSpace: 'nowrap' }}
                    >
                      {row.type}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {row.amount ?? 0} {row.ticker ?? 'tokens'}
                    </StyledTableCell>
                  </TableRow>
                ))}
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Box sx={{ margin: 8 }}>
        <Button
          onClick={() => fetchNextPage()}
          disabled={!hasNextPage || isFetchingNextPage}
        >
          {isFetchingNextPage
            ? 'Loading more...'
            : hasNextPage
            ? 'Load More'
            : 'Nothing more to load'}
        </Button>
      </Box>
    </>
  );
}

async function loadTransactions(connection, publicKey, cursor) {
  const signatures = await connection.getSignaturesForAddress(publicKey, {
    before: cursor,
    limit: SIGNATURES_PER_PAGE,
  });

  for (let i = 0; i < signatures.length; i++) {
    const tx = await connection.getParsedConfirmedTransaction(
      signatures[i].signature,
    );

    try {
      if (tx) {
        const instructions = tx.transaction.message?.instructions?.filter(
          (instruction) =>
            instruction.program === 'system' ||
            instruction.program === 'spl-token',
        );

        signatures[i].type = getType(instructions, publicKey);
        signatures[i].amount =
          getAmount(instructions, publicKey).toFixed(4) * 1;
        signatures[i].ticker = getTicker(instructions);
      }
    } catch (err) {
      console.warn('Failed to parse transaction:', err);
    }
  }

  return signatures;
}

function getTransferInstruction(instructions) {
  return instructions.find(
    (instruction) =>
      instruction.parsed?.type === 'transfer' ||
      instruction.parsed?.type === 'transferChecked',
  );
}

function getAmount(instructions, pubkey) {
  let amount = 0;

  for (const instruction of instructions.filter(
    (instruction) =>
      instruction.parsed?.type === 'transfer' ||
      instruction.parsed?.type === 'transferChecked',
  )) {
    const isOutgoing = isTransferOutgoing(instruction, pubkey);
    const parsedInfo = instruction.parsed?.info;

    // eslint-disable-next-line default-case
    switch (instruction.program) {
      case 'spl-token': {
        amount += parsedInfo.tokenAmount.uiAmount * (isOutgoing ? -1 : 1);
        break;
      }

      case 'system': {
        const lamports = parsedInfo.satomis ?? parsedInfo.lamports ?? 0;
        amount += (lamports / LAMPORTS_PER_SOL) * (isOutgoing ? -1 : 1);
        break;
      }
    }
  }

  return amount;
}

function isTransferOutgoing(transferInstruction, pubkey) {
  switch (transferInstruction.program) {
    case 'spl-token': {
      const sourceAccount = new PublicKey(
        transferInstruction.parsed?.info?.source,
      );
      const pubkeyAtaAddress = findAssociatedTokenAddress(
        pubkey,
        new PublicKey(transferInstruction.parsed?.info?.mint),
        new PublicKey(transferInstruction.programId),
      );
      return sourceAccount.equals(pubkeyAtaAddress);
    }

    case 'system': {
      const sourceAccount = new PublicKey(
        transferInstruction.parsed?.info?.source,
      );
      return sourceAccount.equals(pubkey);
    }

    default:
      return false;
  }
}

function getType(instructions, pubkey) {
  const transferInstruction = getTransferInstruction(instructions);
  if (transferInstruction) {
    return isTransferOutgoing(transferInstruction, pubkey)
      ? strings.send
      : strings.receive;
  }
  return strings.other;
}

function getTicker(instructions) {
  const transferInstruction = getTransferInstruction(instructions);
  if (transferInstruction) {
    return PROGRAM_ID_TO_TICKER_MAPPING[transferInstruction.programId];
  }
  return null;
}
