import { QueryClient } from '@tanstack/react-query';
import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister';
import superjson from 'superjson';
import { Message, PublicKey, Transaction } from '@solana/web3.js';
import { PersistQueryClientOptions } from '@tanstack/react-query-persist-client';

superjson.registerCustom<Buffer, number[]>(
  {
    isApplicable: (v): v is Buffer => v instanceof Buffer,
    serialize: (v) => [...v],
    deserialize: (v) => Buffer.from(v),
  },
  'Buffer',
);

superjson.registerCustom<
  Transaction,
  { data: number[]; hasSignatures: boolean }
>(
  {
    deserialize: (obj) => {
      if (obj.hasSignatures) {
        return Transaction.from(obj.data);
      }
      return Transaction.populate(Message.from(obj.data));
    },
    serialize: (tx) => {
      if (tx.signatures.length) {
        return { data: tx.serialize().toJSON().data, hasSignatures: true };
      }
      return {
        hasSignatures: false,
        data: tx.serializeMessage().toJSON().data,
      };
    },
    isApplicable: (val): val is Transaction => val instanceof Transaction,
  },
  'Transaction',
);

superjson.registerCustom<PublicKey, string>(
  {
    isApplicable: (v): v is PublicKey => v instanceof PublicKey,
    serialize: (v) => v.toBase58(),
    deserialize: (v) => new PublicKey(v),
  },
  'PublicKey',
);

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: true,
      retryDelay: (attemptIndex) => Math.min(1000 * 2 ** attemptIndex, 30000),
    },
  },
});

const localStoragePersister = createSyncStoragePersister({
  storage: window.localStorage,
  serialize: superjson.stringify,
  deserialize: superjson.parse,
});

export const persistOptions: Omit<PersistQueryClientOptions, 'queryClient'> = {
  persister: localStoragePersister,
  dehydrateOptions: {
    shouldDehydrateQuery(query) {
      return query.state.status === 'success' && Boolean(query.meta?.persist);
    },
  },
  maxAge: Infinity,
};
